// Org Unit Endpoints

// Companies
const companies = {
    url: () => "/companies",
    list: () => '/companies',
    create: () => '/companies',
    update: (id: string) =>  `/companies/${id}`,
    delete: (id: string) =>  `/companies/${id}`,
};

// Trades
const trades = {
    url: () => "/trades",
    list: () => '/trades',
    create: () => '/trades',
    update: (id: string) =>  `/trades/${id}`,
    delete: (id: string) =>  `/trades/${id}`,
};

// Divisions
const divisions = {
    url: () => "/divisions",
    list: () => '/divisions',
    create: () => '/divisions',
    update: (id: string) =>  `/divisions/${id}`,
    delete: (id: string) =>  `/divisions/${id}`,
};

// Customers
const customers = {
    url: () => "/customers",
    list: () => '/customers',
    create: () => '/customers',
    update: (id: string) =>  `/customers/${id}`,
    delete: (id: string) =>  `/customers/${id}`,
};

// Job Types
const jobTypes = {
    url: () => "/job-types",
    list: () => '/job-types',
    create: () => '/job-types',
    update: (id: string) =>  `/job-types/${id}`,
    delete: (id: string) =>  `/job-types/${id}`,
};

// Estimators
const estimators = {
    url: () => "/estimators",
    list: () => '/estimators',
    create: () => '/estimators',
    update: (id: string) =>  `/estimators/${id}`,
    delete: (id: string) =>  `/estimators/${id}`,
};

// Supervisors
const supervisors = {
    url: () => "/supervisors",
    list: () => '/supervisors',
    create: () => '/supervisors',
    update: (id: string) =>  `/supervisors/${id}`,
    delete: (id: string) =>  `/supervisors/${id}`,
};

// Foremen
const foremen = {
    url: () => "/foremen",
    list: () => '/foremen',
    create: () => '/foremen',
    update: (id: string) =>  `/foremen/${id}`,
    delete: (id: string) =>  `/foremen/${id}`,
};

// JC Accountants
const jcAccountant = {
    url: () => "/jc-accountants",
    list: () => '/jc-accountants',
    create: () => '/jc-accountants',
    update: (id: string) =>  `/jc-accountants/${id}`,
    delete: (id: string) =>  `/jc-accountants/${id}`,
};

// Cost Types
const costTypes = {
    url: () => "/cost-types",
    list: () => '/cost-types',
    create: () => '/cost-types',
    update: (id: string) =>  `/cost-types/${id}`,
    delete: (id: string) =>  `/cost-types/${id}`,
};

// Cost Code Segment One
const costCodesSegmentOne = {
    url: () => "/cost-code-segment-one",
    list: () => '/cost-code-segment-one',
    create: () => '/cost-code-segment-one',
    update: (id: string) =>  `/cost-code-segment-one/${id}`,
    delete: (id: string) =>  `/cost-code-segment-one/${id}`,
};

// Cost Code Segment Two
const costCodesSegmentTwo = {
    url: () => "/cost-code-segment-two",
    list: () => '/cost-code-segment-two',
    create: () => '/cost-code-segment-two',
    update: (id: string) =>  `/cost-code-segment-two/${id}`,
    delete: (id: string) =>  `/cost-code-segment-two/${id}`,
};

// Cost Codes
const costCodes = {
    url: () => "/cost-codes",
    list: () => '/cost-codes',
    create: () => '/cost-codes',
    update: (id: string) =>  `/cost-codes/${id}`,
    delete: (id: string) =>  `/cost-codes/${id}`,
};

// Warehouses
const warehouses = {
    url: () => "/warehouses",
    list: () => '/warehouses',
    create: () => '/warehouses',
    update: (id: string) => `/warehouses/${id}`,
    delete: (id: string) => `/warehouses/${id}`,
};

// Order Types
const orderTypes = {
    url: () => "/order-types",
    list: () => '/order-types',
    create: () => '/order-types',
    update: (id: string) => `/order-types/${id}`,
    delete: (id: string) => `/order-types/${id}`,
};

// Field Measurement Types
const fieldMeasurementTypes = {
    url: () => "/field-measurement-types",
    list: () => '/field-measurement-types',
    create: () => '/field-measurement-types',
    update: (id: string) => `/field-measurement-types/${id}`,
    delete: (id: string) => `/field-measurement-types/${id}`,
};

// Conditions
const conditions = {
    url: () => "/conditions",
    list: () => '/conditions',
    create: () => '/conditions',
    update: (id: string) => `/conditions/${id}`,
    delete: (id: string) => `/conditions/${id}`,
};

const organizationalUnitEndpoints = {
    companies,
    divisions,
    customers,
    jobTypes,
    estimators,
    supervisors, 
    foremen,
    jcAccountant,
    costTypes,
    costCodesSegmentOne,
    costCodesSegmentTwo,
    costCodes,
    trades,
    warehouses,
    orderTypes,
    fieldMeasurementTypes,
    conditions,
}
export default organizationalUnitEndpoints;
