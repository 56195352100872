// Report Endpoints
// Reports
const report_urls = {
    url: () => "/reports",
    start: () => '/reports/starts',
};
// Field Reports
const field_reports = {
    url: () => "/reports/field",
    getSlabFooting: () => '/reports/field/slab-footing',
    getStartItems: () => '/reports/field/start-items', 
    getFieldSummary: () => '/reports/field/summary',
};

const report_endpoints = {
    report_urls,
    field_reports,
}

export default report_endpoints;