import React from 'react';
import './SideNav.css';
import glossaryIcon from '../images/shepherd-wordmark-color.jpg'; // Add the path to your image
import { useAppSelector, useAppDispatch } from '../../../../app/hooks';
import { toggleSidebar } from '../../../layout/layoutSlice';
import { useMediaQuery } from '@mui/material';
import glossary from './glossary';

const SideNav: React.FC = () => {
  const {isSidebarOpen} = useAppSelector((state: any) => state.layout);
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery('(max-width: 768px)');
  
  const handleGlossaryLinkClick = (link: string) => {
    window.location.href = link;
    if (isMobile) {
      dispatch(toggleSidebar());
    }
  };
  
  return (
    <div className={`sideNav ${isSidebarOpen ? 'open' : 'closed'}`}>
      <div id="top"></div> {/* Ensure this line is at the very top */}
      <div className="glossaryHeader" style={{ paddingTop: '-2cm' }}> {/* Adjust paddingTop to move the image up */}
        <a href="#top">
          <img src={glossaryIcon} alt="Glossary Icon" className="glossaryIcon" />
        </a>
        <h4>Glossary</h4>
      </div>
      <ul>
        {glossary.map((item: any, index: any) => (
          <li key={index}>
            {item.cardTitle ? (
              <>
                <div className="cardTitleSpacer"></div>
                <a href={item.link} className="cardTitleLink">{item.cardTitle}</a>
              </>
            ) : (
              <a href={item.link} onClick={() => handleGlossaryLinkClick(item.link)} className="titleLink">{item.title}</a>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SideNav;