import { useState, useEffect } from 'react';
import { createTheme, ThemeProvider, CssBaseline, Grid, Typography, LinearProgress, Box } from '@mui/material';

// Themed loading overlay component
const LoadingOverlay = ({ themeMode }: { themeMode: 'light' | 'dark' }) => {
  // State to manage animation progress (0-100)
  const [progress, setProgress] = useState(0);
  
  // Effect to control both dot animation and progress bar
  useEffect(() => {
    const interval = setInterval(() => {
      // Update progress in 25% increments, ensuring it reaches 100%
      setProgress((prevProgress) => {
        const nextProgress = prevProgress + 25;
        return nextProgress > 100 ? 0 : nextProgress;
      });
    }, 500);
    
    return () => clearInterval(interval); // Cleanup on unmount
  }, []);
  
  // Calculate dot count based on the progress (0-3)
  // When progress is 100, we show 3 dots (same as 75%)
  const dotCount = progress === 100 ? 4 : Math.floor(progress / 25);

  // Create a minimal theme just for the loading screen
  const loadingTheme = createTheme({
    palette: {
      mode: themeMode,
      primary: {
        main: themeMode === 'light' ? '#2278B2' : '#82b1ff',
      },
      background: {
        default: themeMode === 'light' ? '#f5f5f7' : '#121212',
        paper: themeMode === 'light' ? '#ffffff' : '#1e1e1e',
      },
      text: {
        primary: themeMode === 'light' ? 'rgba(0, 0, 0, 0.87)' : '#ffffff',
      },
    },
  });

  return (
    <ThemeProvider theme={loadingTheme}>
      <CssBaseline />
      <Grid
        container
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'background.default',
          zIndex: 9999,
        }}
      >
        <Box
          sx={{
            width: '110px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 1,
            mb: 2,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: 'text.primary',
              fontWeight: 600,
            }}
          >
            Loading{'.'.repeat(dotCount)}
          </Typography>
          
          {/* Now properly reaches 100% */}
          <LinearProgress 
            variant="determinate" 
            value={progress} 
            sx={{ 
              width: '100%',
              height: 6,
              borderRadius: 3,
              '& .MuiLinearProgress-bar': {
                transition: 'transform 0.2s ease', // Smoother animation
              }
            }} 
          />
        </Box>
      </Grid>
    </ThemeProvider>
  );
};

export default LoadingOverlay;