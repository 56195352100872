

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import appAPI from '../api/appAPI';

export interface UserState {
    userInfo: any;
    avatarUrl: string | null;
    isError: boolean;
    isSuccess: boolean;
    isLoading: boolean;
    message: string;
}

const initialState: UserState = {
    userInfo: null,
    avatarUrl: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
};

// Get user avatar
export const getUserAvatar = createAsyncThunk(
    'user/getAvatar',
    async ({username}: {username: string}, { rejectWithValue }) => {
        try {
            const response = await appAPI.get(`/user/avatar?username=${username}`, {
                responseType: 'blob',  // Important: get the response as a blob
            });
            
            // Create a blob URL from the response data
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const url = URL.createObjectURL(blob);
            return url;
        } catch (error: any) {
            return rejectWithValue(error?.response?.data?.message || 'Failed to fetch avatar');
        }
    }
);

// Update user avatar
export const updateUserAvatar = createAsyncThunk(
    'user/updateAvatar',
    async ({ username, avatarFile }: { username: string, avatarFile: File }, { rejectWithValue }) => {
        try {
            const formData = new FormData();
            formData.append('file', avatarFile);
            formData.append('username', username);
            formData.append('type', 'avatar');
            const response = await appAPI.put(`/user/avatar`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error?.response?.data?.message || 'Failed to update avatar');
        }
    }
);

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = '';
        },
        // Set user info
        setUserInfo: (state, action) => {
            state.userInfo = action.payload;
        },
        // Clear user info
        clearUser: (state) => {
            state.userInfo = null;
            state.avatarUrl = null;
        }
    },
    extraReducers: (builder) => {
        builder
            // Get Avatar
            .addCase(getUserAvatar.fulfilled, (state, action) => {
                state.avatarUrl = action.payload;
            })
            // Update Avatar
            .addCase(updateUserAvatar.fulfilled, (state, action) => {
                state.isSuccess = true;
                state.avatarUrl = action.payload.avatarUrl;
            });
    }
});

export const { reset, clearUser } = userSlice.actions;
export default userSlice.reducer;
