import organizationalUnitEndpoints from "./organizationalUnitEndpoints";
import project_endpoints from "./projectEndpoints";
import start_endpoints from "./startEndpoints";
import assurance_endpoints from "./assuranceEndpoints";
import warehouse_endpoints from "./warehouseEndpoints";
import report_endpoints from "./reportEndpoints";
import { TagTypes } from "../../features/api/tagTypes";
import adminEndpoints from "./adminEndpoints";
import userEndpoints from "./userEndpoints";
// Dropdowns        
const dropdown_urls = { 
    url: "/dropdowns",
    getCompanies: `/dropdowns/companies`,
    getDivisionNumbers: `/dropdowns/divisions`,
    getSupervisors: `/dropdowns/supervisors`,
    getProjects: (company_number: string, division_number: string) => `/dropdowns/projects?company_number=${company_number}&divsion_number=${division_number}`,
    getJobTypes: () => `/dropdowns/job-types`,
    getSovItems: (project_id: string, job_type: string) => `/dropdowns/projects/sov-items?project_id=${project_id}&job_type=${job_type}`,
    getPlans: (company_number: string, job_number: string) => `/dropdowns/starts/job-plans?company_number=${company_number}&job_number=${job_number}`,
    getOptions: (company_number: string, job_number: string, plan: string) => `/dropdowns/starts/plan-options?company_number=${company_number}&job_number=${job_number}&plan=${plan}`,
    getContractNumbers: (project_id: string, job_type_id: string) => `/dropdowns/projects/contracts?project_id=${project_id}&job_type_id=${job_type_id}`,
    getProposalRevisions: (project_id: string, job_type_id: string) => `/dropdowns/projects/proposals?project_id=${project_id}&job_type_id=${job_type_id}`,
    getBudgetRevisions: (project_id: string, job_type_id: string) => `/dropdowns/projects/budgets?project_id=${project_id}&job_type_id=${job_type_id}`,
    getOrderTypes: () => `/dropdowns/order-types`
}
// interface Endpoint {
//     url: () => string;
//     list: () => string;
//     create: () => string;
//     update: (id: string) => string;
//     delete: (id: string) => string;
// }
interface Endpoints {
    [key: string]: any;
}
// Org Unit Endpoints
export const endpoints: Endpoints = {
    // ORG Unit Endpoints
    [TagTypes.ADMIN]: adminEndpoints.admin,
    [TagTypes.USER]: userEndpoints,
    [TagTypes.ADMIN_GROUPS]: adminEndpoints.adminGroups,
    [TagTypes.ADMIN_USER_GROUPS]: adminEndpoints.adminUserGroups,
    [TagTypes.COMPANIES]: organizationalUnitEndpoints.companies,
    [TagTypes.TRADES]: organizationalUnitEndpoints.trades,
    [TagTypes.DIVISIONS]: organizationalUnitEndpoints.divisions,
    [TagTypes.JOB_TYPES]: organizationalUnitEndpoints.jobTypes,
    [TagTypes.CUSTOMERS]: organizationalUnitEndpoints.customers,
    [TagTypes.ESTIMATORS]: organizationalUnitEndpoints.estimators,
    [TagTypes.SUPERVISORS]: organizationalUnitEndpoints.supervisors,
    [TagTypes.FOREMEN]: organizationalUnitEndpoints.foremen,
    [TagTypes.ACCOUNTANTS]: organizationalUnitEndpoints.jcAccountant,
    [TagTypes.COST_TYPES]: organizationalUnitEndpoints.costTypes,
    [TagTypes.COST_CODE_SEGMENT_ONE]: organizationalUnitEndpoints.costCodesSegmentOne,
    [TagTypes.COST_CODE_SEGMENT_TWO]: organizationalUnitEndpoints.costCodesSegmentTwo,
    [TagTypes.COST_CODES]: organizationalUnitEndpoints.costCodes,
    [TagTypes.ORDER_TYPES]: organizationalUnitEndpoints.orderTypes,
    [TagTypes.FIELD_MEASUREMENT_TYPES]: organizationalUnitEndpoints.fieldMeasurementTypes,
    [TagTypes.CONDITIONS]: organizationalUnitEndpoints.conditions,
    // Dropdown Endpoints
    [TagTypes.DROPDOWNS]: dropdown_urls,
    // Project Endpoints
    [TagTypes.PROJECTS]: project_endpoints.project_urls,
    [TagTypes.PROJECT_MAPPINGS]: project_endpoints.projectMappings,
    [TagTypes.INFORMATION]: project_endpoints.projectInformation,
    [TagTypes.PLANDESIGN]: project_endpoints.planDesigns,
    [TagTypes.SPECIFICATIONS]: project_endpoints.specifications,
    [TagTypes.CHECKLIST]: project_endpoints.checklist,
    [TagTypes.BUDGETS]: project_endpoints.budgets,
    [TagTypes.BUDGET_ITEMS]: project_endpoints.budgetItems,
    [TagTypes.BUDGET_SUB_UNITS]: project_endpoints.budgetItems,
    [TagTypes.PROPOSALS]: project_endpoints.proposals,
    [TagTypes.PROPOSAL_ITEMS]: project_endpoints.proposalItems,
    [TagTypes.CONTRACTS]: project_endpoints.contracts,
    [TagTypes.CONTRACT_ITEMS]: project_endpoints.contractItems,
    [TagTypes.PREBUILT_ORDERS]: project_endpoints.prebuiltOrders,
    [TagTypes.PREBUILT_ORDER_ITEMS]: project_endpoints.prebuiltOrderItems,
    [TagTypes.PREBUILT_ITEMS]: project_endpoints.prebuiltItems,
    [TagTypes.FIELD_QUANTITIES]: project_endpoints.fieldQuantities,
    [TagTypes.LOTS]: project_endpoints.lots,
    [TagTypes.SOV]: project_endpoints.sov,
    [TagTypes.SOV_ITEMS]: project_endpoints.sovItems,
    [TagTypes.INCLUSIONS_EXCLUSIONS]: project_endpoints.inclusionsExclusions,
    [TagTypes.RFI]: project_endpoints.rfi,
    // Start Endpoints
    [TagTypes.STARTS]: start_endpoints.starts,
    [TagTypes.STARTS_STATUS]: start_endpoints.startsStatus,
    [TagTypes.START_ITEMS_BY_LOT_ID]: start_endpoints.lotsByStartID,
    [TagTypes.START_ITEMS]: start_endpoints.startItems,
    [TagTypes.COORDINATOR_CHECKLIST]: start_endpoints.checklist,
    [TagTypes.FIELD_MEASUREMENTS]: start_endpoints.fieldMeasurements,
    [TagTypes.FIELD_MEASUREMENT_ITEMS]: start_endpoints.fieldMeasurementItems,
    // Assurance Endpoints
    [TagTypes.ASSURANCE]: assurance_endpoints.assurance,
    [TagTypes.STAGING_ORDERS]: assurance_endpoints.stagingOrders,
    [TagTypes.FUTURE_ORDERS]: assurance_endpoints.futureOrders,
    // Warehouse Endpoints
    [TagTypes.WAREHOUSES]: warehouse_endpoints.warehouses,
    [TagTypes.WAREHOUSE_ORDERS]: warehouse_endpoints.warehouseOrders,
    [TagTypes.WAREHOUSE_MATERIALS]: warehouse_endpoints.materials,
    [TagTypes.WAREHOUSE_ORDERS_BY_START_ID]: warehouse_endpoints.warehouseOrdersByStartID,
    [TagTypes.WAREHOUSE_ORDER_ITEMS]: warehouse_endpoints.warehouseOrderItems,
    [TagTypes.WAREHOUSE_ORDERS_DEFAULT]: warehouse_endpoints.warehouseOrdersDefault,
    [TagTypes.WAREHOUSE_ORDER_ITEMS_DEFAULT]: warehouse_endpoints.warehouseOrderItemsDefault,
    // Report Endpoints
    [TagTypes.REPORTS]: report_endpoints.report_urls,
    [TagTypes.FIELD_REPORTS]: report_endpoints.field_reports,
} as const;

export default endpoints;