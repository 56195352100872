import { useEffect, FC } from 'react';
import './UserGuide.css';
import SideNav from './side-nav/SideNav';
import { guideSections } from './content'; // Import the content

const UserGuide: FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  return (
    <div className="userGuideContainer" id="top">
      <SideNav />
      <div className="content">
        {/* Guide Sections */}
        {guideSections.map((section, index) => (
          <div key={index} className="section" id={section.title?.toLowerCase().replace(/\s+/g, '-')}>
            {section.cardTitle && (
              <>
                <h5 className="cardTitle" id={section.cardTitle.toLowerCase().replace(/\s+/g, '-')}>{section.cardTitle}</h5>
                <hr className="sectionBreak" />
              </>
            )}
            {section.title && (
              <h5 className="sectionTitle">{section.title}</h5>
            )}
            {section.heading && (
              <h6 className="heading">{section.heading}</h6> // Apply the CSS class here
            )}
            <div className="contentWrapper">
              {section.image && (
                <img src={section.image} alt={section.title} className="sectionImage" />
              )}
              <div className="textContent">

                {section.presentations && section.presentations.map((presentation, presentationIndex) => (
                  <div key={presentationIndex} className="presentationWrapper">
                    <iframe
                      src={presentation}
                      frameBorder="0"
                      allowFullScreen
                      title={`${section.title} - Presentation ${presentationIndex + 1}`}
                    ></iframe>
                  </div>
                ))}
                {section.video && (
                  <div className="videoWrapper">
                    <div className="videoContainer">
                      <iframe
                        src={section.video}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title={section.title}
                      ></iframe>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}

        {/* Embed Miro Board */}
        <div className="miroBoardContainer">
          <iframe
            width="1536"
            height="864"
            src="https://miro.com/app/live-embed/uXjVLm5Nu1k=/?moveToViewport=-34804,-42625,8143,5160&embedId=222911057602"
            frameBorder="0"
            scrolling="no"
            allow="fullscreen; clipboard-read; clipboard-write"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default UserGuide;