// Start Endpoints
// Starts
const starts = {
    findById: (id: string) => `/starts/${id}`,
    url: () => '/starts',
    list: () => '/starts',
    create: (project_id: string) => `/starts?project_id=${project_id}`,
    update: (id: string) => `/starts?id=${id}`,
    delete: (id: string) => `/starts?id=${id}`,
};
// Starts Statuses
const startsStatus = {
    url: () => "/start-statuses",
    list: () => '/start-statuses',
    update: (id: string) =>  `/start-statuses/${id}`,
    delete: (id: string) =>  `/start-statuses/${id}`,
};
// Release Info
const lotsByStartID = {
    url: () => '/starts/items/lots',
    list: () => '/starts/items/lots',
    create: () => `/starts/items`,
    update: (id: string) => `/lots?id=${id}`,
    delete: (lot_id: string, start_id: string, ) => `/starts/items/lots?lot_id=${lot_id}&start_id=${start_id}`,
};
// Start Items
const startItems = {
    url: () => '/starts/items',
    list: () => '/starts/items',
    create: (start_id: string) => `/starts/items?start_id=${start_id}`,
    update: (id: string) => `/starts/items?id=${id}`,
    delete: (id: string) => `/starts/items?id=${id}`,
};

// Checklist
const checklist = {
    url: () => '/checklist',
    list: () => '/checklist',
    create: () => `/checklist`,
    update: (id: string, start_id: string) => `/checklist?start_id=${start_id}&id=${id}`,
    delete: (id: string) => `/checklist?id=${id}`,
};

// Field Measurements
const fieldMeasurements = {
    url: () => '/field-measurements',
    list: () => '/field-measurements',
    create: (start_id: string, field_measurement_type_id: string) => `/field-measurements?start_id=${start_id}&field_measurement_type_id=${field_measurement_type_id}`,
    update: (id: string) => `/field-measurements?id=${id}`,
    delete: (id: string) => `/field-measurements?id=${id}`,
};
// Field Measurement Items
const fieldMeasurementItems = {
    url: () => '/field-measurement-items',
    list: () => '/field-measurement-items',
    create: (start_item_id: string, field_measurement_type_id: string) => `/field-measurements?start_item_id=${start_item_id}&field_measurement_type_id=${field_measurement_type_id}`,
    update: (id: string) => `/field-measurements?id=${id}`,
    delete: (id: string) => `/field-measurements${id ? `/${id}` : ''}`,
};

const start_endpoints = {  
    starts,
    startsStatus,
    startItems,
    lotsByStartID,
    checklist,
    fieldMeasurements,
    fieldMeasurementItems,
}

export default start_endpoints;