import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import authService from './authService';
import { confirmResetPassword, rememberDevice, resetPassword, signIn, signOut, updatePassword } from 'aws-amplify/auth';
import appAPI from '../api/appAPI';
import { showSnackbar } from '../components/snackbar/snackbarSlice';

export interface AuthState {
    session:any;
    userDetails: any;
    company: any;
    division: any;
    selectedSuite: string;
    suiteDetailsConfirmed: boolean;
    isAuthenticated: boolean;
    isError: boolean;
    isSuccess: boolean;
    isLoading: boolean;
};

const initialState: AuthState = {
    session: {},
    company: {},
    division: {},
    selectedSuite: "",
    userDetails: {},
    suiteDetailsConfirmed: false,
    isAuthenticated: false,
    isError: false,
    isSuccess: false,
    isLoading: false,
};

// Register user
export const register = createAsyncThunk(
    'api/users',
    async ( _user:any, { rejectWithValue }) => {
        try {
            return null;
        } catch (error:any) {
            return rejectWithValue(error?.message || 'Registration failed.');
        }
    }
);

export const rememberUserDevice = createAsyncThunk(
    'auth/rememberUserDevice',
    async (_, { rejectWithValue }) => {
        try {
            await rememberDevice();
            return true;
        } catch (error:any) {
            return rejectWithValue(error?.message || 'Remember device failed.');
        }
    }
);
// Login User
export const login = createAsyncThunk(
    'auth/login',
    async ({ username, password }:any, { rejectWithValue }) => {
        try {
            const { isSignedIn } = await signIn({ username, password });
            if(isSignedIn){
                const response = await appAPI.post('auth/login', {});
                if(response.status !== 200){
                    throw new Error('Login failed on server');
                }
                const userDetails = response.data.userDetails;
                return {isSignedIn, userDetails};
            }
            return {isSignedIn: isSignedIn, userDetails: null};
        } catch (error:any) {
            console.log(error);
            return rejectWithValue(error?.message || 'Login failed.');
        }
    }
);
// Logout
export const logout = createAsyncThunk(
    'auth/signout',
    async (_, {dispatch, rejectWithValue }) => {
        try {
            await signOut();
            //Clear cookie
            const response = await appAPI.post('auth/signout', {});
            if (response.status !== 200) {
                throw new Error('Logout failed on server');
            }
            sessionStorage.clear(); 
            dispatch(reset());
            return true; 
        } catch (error:any) {
            return rejectWithValue(error?.message || 'Logout failed.');
        }
    }
);

// Get current authenticated user
export const currentAuthenticatedUser = createAsyncThunk(
    'auth/getAuthenticatedUser',
    async (_, { rejectWithValue }) => {
        try {
            const userDetails = await authService.getCurrentUser();
            return userDetails;
        } catch (error: any) {
            // Clear any stale cookies if authentication fails
            authService.clearSession();
            return rejectWithValue('Not authenticated');
        }
    }
);

// Password Reset
export const resetUserPassword = createAsyncThunk(
    'auth/resetUserPassword',
    async ({ username }:any, { rejectWithValue, dispatch }) => {
        try {
            const output = await resetPassword({
                username: username
            });
            
            const { nextStep } = output;
            switch (nextStep.resetPasswordStep) {
                case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
                const codeDeliveryDetails = nextStep.codeDeliveryDetails;
                    dispatch(showSnackbar({
                        message: `Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`,
                        severity: 'success'
                    }));
                // Collect the confirmation code from the user and pass to confirmResetPassword.
                break;
                case 'DONE':
                    dispatch(showSnackbar({
                        message: 'Successfully reset password.',
                        severity: 'success'
                    }));
                break;
            }
            return 'Password reset successful.';
        } catch (error:any) {
            console.log(error)
            return rejectWithValue(error?.message || 'Password reset failed.');
        }
    }
);

export const confirmUserPasswordReset = createAsyncThunk(
    'auth/confirmUserPasswordReset',
    async ({ username, confirmationCode, newPassword }:any, { rejectWithValue }) => {
        try {
            const output = await confirmResetPassword({
                username: username,
                confirmationCode: confirmationCode,
                newPassword: newPassword
            });
            return output;
        } catch (error:any) {
            return rejectWithValue(error?.message || 'Password reset confirmation failed.');
        }
    }
);

// User Password Update
export const updateUserPassword = createAsyncThunk(
    'auth/updateUserPassword',
    async ({oldPassword, newPassword }:any, { rejectWithValue }) => {
        try {
            await updatePassword({
                oldPassword: oldPassword,
                newPassword: newPassword,
            });
            return 'Password updated successfully.';
        } catch (error:any) {
            return rejectWithValue(error?.message || 'Password update failed.');
        }
    }
);

// Redux Slice
export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        reset: (state) => {
            // Properly reset all state properties
            Object.assign(state, initialState);
        },
        redirectToLogin: (_state) => {
            window.location.href = '/login';
        },
        setUserDetails: (state, action) => {
            state.userDetails = action.payload;
        },
        setSuiteDetails: (state, action) => {
            try {
              // Set company and division from payload, defaulting to empty objects
              state.company = action.payload?.company || {};
              state.division = action.payload?.division || {};
              state.selectedSuite = action.payload?.selectedSuite;
          
              // Confirm suite details only if both company and division have keys (i.e., are non-empty)
              state.suiteDetailsConfirmed =
                Object.keys(state.company).length > 0 && Object.keys(state.division).length > 0;
          
              // Serialize and store suite details only if both values are present
              const serialized = JSON.stringify({
                suiteDetailsConfirmed: state.suiteDetailsConfirmed,
                company: state.company,
                division: state.division,
                selectedSuite: action.payload?.selectedSuite,
              });
          
              sessionStorage.setItem('suiteDetails', serialized);
            } catch (error: any) {
              console.log("Error serializing suite details:", error);
            }
        },          
        loadSuiteDetails: () => {
            const serialized = sessionStorage.getItem('suiteDetails');
            if(serialized === null){
                return undefined;
            }
            return JSON.parse(serialized);
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(logout.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(logout.fulfilled, (state) => {
            Object.assign(state, initialState);
        })
        .addCase(logout.rejected, (state, _action) => {
            state.isLoading = false;
            state.isError = true;
        })
        .addCase(login.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(login.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.isAuthenticated = action.payload.isSignedIn;
            state.userDetails = action.payload.userDetails;
        })
        .addCase(login.rejected, (state, _action) => {
            state.isLoading = false;
            state.isError = true;
            state.isAuthenticated = false;
            state.userDetails = null;
        })
        .addCase(currentAuthenticatedUser.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(currentAuthenticatedUser.fulfilled, (state, action) => {
            state.isAuthenticated = true;
            state.isLoading = false;
            state.userDetails = action.payload;
        })
        .addCase(currentAuthenticatedUser.rejected, (state, _action) => {
            state.isAuthenticated = false;
            state.isLoading = false;
            state.isError = true;
        })
        .addCase(resetUserPassword.pending, (state) => { 
            state.isLoading = true;
        })
        .addCase(resetUserPassword.fulfilled, (state, _action) => {
            state.isLoading = false;
            state.isSuccess = true;
        })
        .addCase(resetUserPassword.rejected, (state, _action) => {
            state.isLoading = false;
            state.isError = true;
        })
        .addCase(rememberUserDevice.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(rememberUserDevice.fulfilled, (state, _action) => {
            state.isLoading = false;
        })
        .addCase(rememberUserDevice.rejected, (state, _action) => {
            state.isLoading = false;
            state.isError = true;
        })
    },
});

export const { reset, redirectToLogin, setSuiteDetails, loadSuiteDetails, setUserDetails } = authSlice.actions;
export const selectIsAuthenticated = (state: any) => state.auth.isAuthenticated;
export default authSlice.reducer;