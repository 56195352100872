import { createSlice } from "@reduxjs/toolkit";



interface StepperState {
    activeStep: number;
    steps: any[];
    createdData: any[];
    completed: Record<number, boolean>;
    dialogOpen: boolean;
}


const initialState: StepperState = {
    activeStep: 0,
    steps: [],
    createdData: [],
    completed: {},
    dialogOpen: false,
};

const stepperSlice = createSlice({
    name: 'stepper',
    initialState,
    reducers: {
        resetStepper: (state) => {
            state.activeStep = 0;
            state.completed = {};
            state.createdData = [];
            state.steps = [];
            state.dialogOpen = false;
        },
        setActiveStep: (state, action) => {
            state.activeStep = action.payload;
        },
        setSteps: (state, action) => {
            state.steps = action.payload;
        },
        setCompleted: (state, action) => {
            state.completed = {...state.completed, ...action.payload};
        },
        setCreatedData: (state, action) => {
            if(Array.isArray(action.payload)){
                state.createdData = action.payload;
            } else {
                state.createdData = [action.payload];
            }
        },
        setDialogOpen: (state, action) => {
            state.dialogOpen = action.payload;
        }
    },
});

export const { setActiveStep, setSteps, setCreatedData, setCompleted, resetStepper, setDialogOpen } = stepperSlice.actions;
export default stepperSlice.reducer;
