import { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import Layout from './features/layout/Layout';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { currentAuthenticatedUser, reset, setUserDetails } from './features/auth/authSlice';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { Amplify } from 'aws-amplify';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { CookieStorage } from 'aws-amplify/utils';
import { showSnackbar } from './features/components/snackbar/snackbarSlice';
import LoadingOverlay from './features/components/overlay/LoadingOverlay';
// Configure Amplify at the top level
Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID || '',
      userPoolClientId: process.env.REACT_APP_AWS_CLIENT_ID || '',
      identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID || '',
      allowGuestAccess: false
    }
  }
});

// Set cookie storage separately using the token provider
cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage({
    domain: window.location.hostname,
    path: '/',
    expires: 365,
    sameSite: 'strict',
    secure: true,
  }
));

function ThemeVariablesInjector({ children }: { children: any }) {
  const theme = useTheme();
  
  useEffect(() => {
    // Set CSS variables based on theme
    document.documentElement.style.setProperty('--hover-bg-light', 'rgba(0, 0, 0, 0.04)');
    document.documentElement.style.setProperty('--hover-bg-dark', 'rgba(255, 255, 255, 0.08)');
    document.documentElement.style.setProperty('--current-theme-mode', theme.palette.mode);
    document.documentElement.style.setProperty('--current-theme-mode-light', theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.04)' : 'rgba(255, 255, 255, 0.08)');
    document.documentElement.style.setProperty('--current-theme-mode-dark', theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.04)' : 'rgba(255, 255, 255, 0.08)');
    document.documentElement.style.setProperty('--hover-current-theme-mode-main', theme.palette.primary.main);
  }, [theme.palette.mode, theme.palette.primary.main]);
  
  return children;
}

function App() {
  const { themeMode, themeConfig } = useAppSelector((state: any) => state.layout);
  const { isLoading } = useAppSelector((state: any) => state.auth);
  const [theme, setTheme] = useState<any>({});
  const [isInitialized, setIsInitialized] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const checkUser = async () => {
      setIsInitialized(true);
      try {
        const user = await dispatch(currentAuthenticatedUser()).unwrap();
        dispatch(setUserDetails(user));
      } catch (error) {
        dispatch(showSnackbar({
          message: 'Session check error',
          severity: 'error'
        }));
        dispatch(reset());
      } finally {
        setIsInitialized(true);
      }
    };

    checkUser();
  }, [dispatch]);

  useEffect(() => {
    if (themeConfig) {
      setTheme(
        createTheme({
          ...themeConfig,
          palette: {
            ...themeConfig.palette,
            mode: themeMode,
          },
        })
      );
    }
  }, [themeConfig, themeMode]);

  if (!isInitialized || isLoading) {
    return <LoadingOverlay themeMode={themeMode || 'light'} />;
  }

  return (
    <Router>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme}>
          <CssBaseline enableColorScheme />
          <ThemeVariablesInjector>
            <Layout />
          </ThemeVariablesInjector>
        </ThemeProvider>
      </LocalizationProvider>
    </Router>
  );
}

export default App;
