import { useState, useMemo } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate, useLocation, Link as RouterLink } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { logout, reset } from '../../auth/authSlice';
import { CircularProgress, useTheme } from '@mui/material';
import MenuSharpIcon from '@mui/icons-material/MenuSharp';
import MenuOpenSharpIcon from '@mui/icons-material/MenuOpenSharp';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { openSidebar, toggleSidebar, toggleThemeMode } from '../layoutSlice';
import usePageLinks from '../../components/hooks/usePageLinks';
import styles from './appBar.module.css';
import { URLS } from '../../../routes/urls/urls';
import useUserAvatar from '../../user/hooks/useUserAvatar';
import useRenderMenus from './useRenderMenus';

function ResponsiveAppBar() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { isSidebarOpen, themeMode } = useAppSelector((state: any) => state.layout);
  const { userDetails, suiteDetailsConfirmed } = useAppSelector((state: any) => state.auth);
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const theme = useTheme();
  const isOrgUnitRoute = useMemo(() => location.pathname.includes('organizational-units'), [location.pathname]);
  const pages = usePageLinks();

  const { renderMobileMenu, renderDesktopMenu } = useRenderMenus({
    pages,
    styles,
    isOrgUnitRoute,
    isSidebarOpen,
    anchorElNav,
    onClose: () => {
      setAnchorElNav(null);
      dispatch(toggleSidebar());
    },
    toggleSidebar: () => dispatch(toggleSidebar()),
  });
  // Get username and avatar
  const username = useMemo(() => userDetails?.username, [userDetails]);
  const { avatarUrl, isLoading } = useUserAvatar(username);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
    dispatch(toggleSidebar());
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = async () => {
    await dispatch(logout());
    dispatch(reset());
    navigate('/login');
  };

  const handleProfile = () => {
    navigate('/profile');
    handleCloseUserMenu();
  };

  const handleUserGuide = () => {
    navigate(URLS.userGuide);
    handleCloseUserMenu();
    dispatch(openSidebar());
  };

  // AppBar styles
  const appBarStyle = {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.light,
    borderBottom: `0.1em solid ${theme.palette.primary.light}`,
    color: theme.palette.text.primary,
  };

  if(!suiteDetailsConfirmed) return null;

  return (
    <AppBar color="primary" position="sticky" className={styles.appBar} sx={appBarStyle}>
      <Toolbar disableGutters 
        className={styles.toolbar} 
        sx={{ 
          backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.primary.dark,
         // borderBottom: `0.1em solid ${theme.palette.primary.light}`
        }}
      >
        <Box className={styles.leftSection}>
          <IconButton
            size="large"
            onClick={handleClick}
            color="inherit"
            disableRipple
            className={styles.menuButton}
          >
            {isSidebarOpen ? (
              <MenuOpenSharpIcon sx={{ color: theme.palette.primary.light }} fontSize="medium" />
            ) : (
              <MenuSharpIcon sx={{ color: theme.palette.primary.light }} fontSize="medium" />
            )}
          </IconButton>

          {renderMobileMenu}
          {renderDesktopMenu}
        </Box>

        <Box className={styles.rightSection} style={{ color: theme.palette.primary.light }}>
          <IconButton
            className={styles.themeButton}
            onClick={() => dispatch(toggleThemeMode())}
            color="inherit"
            sx={{ color: theme.palette.primary.light }}
          >
            {themeMode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>

          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              {isLoading ? (
                <CircularProgress size={40} color="inherit" />
              ) : (
                <Avatar
                  alt={username}
                  src={avatarUrl || undefined}
                  sx={{ width: 50, height: 50, border: `0.1em solid ${theme.palette.primary.light}` }}
                >
                  {!avatarUrl && username?.charAt(0).toUpperCase()}
                </Avatar>
              )}
            </IconButton>
          </Tooltip>

          <Menu
            className={styles.userMenu}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            keepMounted
            sx={{ 
              '& .MuiPaper-root': { 
                backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.light, 
                color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark,
                marginTop: '8px'
              } 
            }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem key={0} onClick={handleProfile}>
              <Typography className={styles.userMenuTypography}>
                <RouterLink
                  to={URLS.userProfile}
                  className={styles.userLink}
                  onClick={handleCloseUserMenu}
                  style={{ textDecoration: 'none', color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark }}
                >
                  Profile
                </RouterLink>
              </Typography>
            </MenuItem>
            <MenuItem key={1} onClick={handleUserGuide}>
              <Typography className={styles.userMenuTypography}>
                <RouterLink
                  to={URLS.userGuide}
                  className={styles.userLink}
                  onClick={handleCloseUserMenu}
                  style={{ textDecoration: 'none', color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark }}
                >
                  User Guide
                </RouterLink>
              </Typography>
            </MenuItem>
            <MenuItem key={2} onClick={handleLogout}>
              <Typography color={theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark} sx={{ fontWeight: 600 }} className={styles.userMenuTypography}>
                Logout
              </Typography>
            </MenuItem>
          </Menu> 
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default ResponsiveAppBar;