import { configureStore, combineReducers, ThunkAction, Action } from '@reduxjs/toolkit';
import { dropdownApi } from '../features/dropdowns/dropdownApi';
import authReducer, { loadSuiteDetails } from '../features/auth/authSlice';
import projectSlice from '../features/projects/projectSlice';
import layoutReducer from '../features/layout/layoutSlice';
import reportReducer from '../features/reports/reportSlice';
import dropdownReducer from '../features/dropdowns/dropdownSlice';
import SnackbarReducer from '../features/components/snackbar/snackbarSlice';
import { apiSliceV2 } from '../features/api/apiSliceV2';
import SovReducer from '../features/components/forms/sovIDSlice';
import headerReducer from '../features/components/header/headerSlice';
import gridReducer from '../features/components/data-grids/layout/gridSlice';
import errorReducer from '../features/components/forms/errorSlice';
import userReducer from '../features/user/userSlice';
import warehouseReducer from '../features/warehouse/warehouseSlice';
import stepperReducer from '../features/components/stepper/stepperSlice';
// Combine all the reducers
const rootReducer = combineReducers({
  auth: authReducer,
  project: projectSlice,
  layout: layoutReducer,
  report: reportReducer,
  dropdown: dropdownReducer,
  snack: SnackbarReducer,
  sov: SovReducer,
  grid: gridReducer,
  error: errorReducer,
  user: userReducer,
  [dropdownApi.reducerPath]: dropdownApi.reducer, 
  [apiSliceV2.reducerPath]: apiSliceV2.reducer,
  header: headerReducer,
  warehouse: warehouseReducer,
  stepper: stepperReducer
});

// Create a function to configure the store
const initializeStore = () => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(dropdownApi.middleware, apiSliceV2.middleware),
    devTools: process.env.NODE_ENV === 'development',
  });

  // Hydrate the state by dispatching loadSuiteDetails
  store.dispatch(loadSuiteDetails());

  return store;
};

// Export the store
export const store = initializeStore();

// Define the types for RootState, AppDispatch, and AppThunk
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
