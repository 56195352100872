import ShepherdLogowName from './images/ShepherdLogowName.png';
export const guideSections = [
    {
      UserguideTitle: "Get Started with",
      image: ShepherdLogowName,
      UseguideCatchPhrase: "Want results? Propose a solution to the challenge.",
      UseguideCatchMotto: "Our goal is to centralize our tribal knowledge, fostering an environment where we teach and learn from one another. This collaboration is essential for growth and success.",
    },

    {
        title: "Get started with Shepherd.",
        heading: "Find your Start.",
        description: "Want results? Propose a solution to the challenge.",
        details: [
            "Our goal is to centralize our tribal knowledge, fostering an environment where we teach and learn from one another.",
            "This collaboration is essential for growth and success.",
        ],
    },

    {
        cardTitle: "Access",
    },

    {
      title: "Logging in to the App",
      heading: "How do I log in to the app?",
      description: "Follow these steps to log into the Shepherd app.",
      subdescription: "Ensure you have your credentials ready before logging in.",
      details: [
        "Registering: In-app user registration can be completed by your admin team. If a user needs to be set as Admin, please email one of the following team members to get set up with an account: scott@shepherd-software.net, carlos@shepherd-software.net.",
        "Entering Credentials: Enter your provided username into the username field and your chosen password into the Password field.",
        "Saving your Credentials: Click the 'Remember me' box to save your email and password for future logins.",
        "Sign In Button: Left-click the 'Sign In' button after entering your credentials to complete the login process.",
      ],
    },
    {
      title: "Logging out of the App",
      heading: "How do I log out of the app?",
      description: "Logging out of Shepherd is simple and ensures account security.",
      subdescription: "You can log out using the user avatar menu or the sidebar.",
      details: [
        "The Logout button is located in a dropdown menu within your user avatar image.",
        "Left-click the avatar image, then select the Logout button.",
        "Alternatively, left-click on the 'Logout' button at the bottom of the Sidebar Navigator on the left.",
      ],
      //image: "https://drive.google.com/uc?export=view&id=16hfJ9gwYI91cobA_RT4xgtMQf8Mf1IrU",
    },
    {
        cardTitle: "Organizational Units",
    },
  
      {
        title: "Create Company",
        heading: "How to create a company within Organizational Units",
        description: "Creating a company is the very first required step.",
        subdescription: "To get started click on the Organizational Units tab in the home screen.",
        details: [
          "Then make sure you are in the Companies tab.",
          "You are now ready to add a Company.",
          "Add a Company: Follow the step-by-step guide in the link above to add a Company to Organizational Units.",
          "A created Company set will look like this: The data comes from the Organizational Unit Excel document, Company sheet.",
        ],
        presentations: [
            "https://docs.google.com/presentation/d/1ChCZPhz_YkcVF64Fp1FQd--SXLk75JWq/embed?start=true&loop=true&delayms=3000"
            ]
        },
  
      {
        title: "Create Division",
        heading: "How to create a Division within Organizational Units",
        description: "Now that you have created a Company you are ready to create a Division within that company.",
        subdescription: "Let's go through that process. Click into the Organizational Units tab.",
        details: [
          "Now let's enter the Company you just created by clicking the company number.",
          "Navigate to the Divisions tab. You are now ready to add a Division.",
          "Add a Division: Follow the step-by-step guide in the link above to add a division to Organizational Units.",
          "A created Division set will look like below: The data comes from the Organizational Unit excel.",
        ],
        presentations: [
            "https://docs.google.com/presentation/d/15Ct4M4ZqX8vJfgVlfC6l7FSV82KT_xug/embed?start=true&loop=true&delayms=3000"
            ]
        },
  
      {
        title: "Create Customers",
        heading: "How to create Customers within Organizational Units",
        description: "At this point you have created a Company and a Division within that company.",
        subdescription: "Let's now add Customers to your company. Click into the Organizational Units tab in your homescreen.",
        details: [
          "Let's enter the Company you just created by clicking the company number.",
          "Navigate to the customers tab. You are now ready to create customers.",
          "Add Customers: Follow the step-by-step guide in the link above to add customers to Organizational Units.",
          "A created Customers set will look like below: The data comes from the Organizational Unit excel.",
        ],
        presentations: [ 
            "https://docs.google.com/presentation/d/16BhZ4vlA05NAfAHZ3lIGVE6M9E-pyFqK/embed?start=true&loop=true&delayms=3000"
            ]
      },
  
      {
        title: "Create Warehouses",
        heading: "How to create Warehouses within Organizational Units",
        description: "At this point you have created a Company, a Division and Customers within that company.",
        subdescription: "Let's now add a warehouse. Click into the Organizational Units tab in your homescreen.",
        details: [
          "Click into the Company by clicking the company number.",
          "Navigate to the Warehouses tab. You are now ready to add a Warehouse.",
          "Add Warehouses: Follow the step-by-step guide in the link above to add Warehouses to Organizational Units.",
          "A created Warehouse set will look like below: The data comes from the Organizational Unit excel.",
        ],
        presentations: [
            "https://docs.google.com/presentation/d/1cKwNsWD-ocLc-cIhuPZSTGckOyFqmsdp/embed?start=true&loop=true&delayms=3000"
            ]
      },
  
      {
        title: "Create Job Types",
        heading: "How to create Job Types within Organizational Units",
        description: "We will now begin to fill out all the information that relates to a specific Division.",
        subdescription: "The first thing on the list is Job Types.",
        details: [
          "Navigate to Job Types: Org Units > Companies > Divisions > Job Types.",
          "You are now ready to add Job Types.",
          "Add Job Types: Follow the step-by-step guide in the link above to add job types to Organizational Units.",
          "A created Job Type set will look like below: The data comes from the Organizational Unit excel.",
        ],
        presentations: [
            "https://docs.google.com/presentation/d/1d1nxMW1xGpftPNRv3-5greIYBnurMt68/embed?start=true&loop=true&delayms=3000"
            ]
      },
  
      {
        title: "Create Roles",
        heading: "How to create roles within Organizational Units",
        description: "Navigate to your Division.",
        subdescription: "Org Units > Companies > Divisions >",
        details: [
          "What Roles are required to be entered into Organizational Units?",
          "Estimators",
          "Supervisors",
          "Foremen",
          "JC Accountants",
          "Where can I find the data?",
          "Estimators",
          "Supervisors",
          "Foremen",
          "JC Accountants",
          "Add Roles: Follow the step-by-step guide in the link above to add roles to Organizational Units.",
        ],
        presentations: [
            "https://docs.google.com/presentation/d/1TzVKOfZ8cRy-wzNQP6ksbMYj9JnAHLQu/embed?start=true&loop=true&delayms=3000"
            ]
      },
  
      {
        cardTitle: "Projects",
    },
  
    {
      title: "Access the Project List",
      heading: "You can access the project list through Projects.",
      description: "Create a Project",
      subdescription: "In Org Units we entered in all the info regarding us and our company. In Projects we will be entering all the info that relates to a particular project or job. This is where we will import plan designs, schedule of values, and budgets among other things.",
      details: [
        "Let's get started.",
        "Click on Projects and make sure you are in the right Company and Division.",
        "Then Click Create Project.",
        "Create your Project: Follow the step-by-step guide in the link above to create your project.",
      ],
    },
  
    {
      title: "Create a Project",
      heading: "You can access the create a project through Projects.",
      description: "Create a Project",
      subdescription: "In Org Units we entered in all the info regarding us and our company. In Projects we will be entering all the info that relates to a particular project or job. This is where we will import plan designs, schedule of values, and budgets among other things.",
      details: [
        "Let's get started.",
        "Click on Projects and make sure you are in the right Company and Division.",
        "Then Click Create Project.",
        "Create your Project: Follow the step-by-step guide in the link above to create your project.",
      ],
      presentations:[
        "https://docs.google.com/presentation/d/1Y86weM_XuDo9-D1rP7-6c7jMaYCUzfpM/embed?start=true&loop=true&delayms=3000"
        ]
    },
  
    {
      title: "Create Project Information",
      heading: "What is included in the project Information tab?",
      description: "The project Information tab holds data that may not fit anywhere else. It’s a quick way to reference and update information for a particular project. Below is a great example.",
      details: [
        "There are three main ways to input data into the Information tab.",
        "Copy & Paste Project Information",
        "Follow the step-by-step guide in the link above to copy & paste Project Information.",
        "Copy & Paste data",
        "Quick and simple way to input data.",
        "The copied data needs to be formatted correctly.",
        "Recommended you use the Export Project Template as a guide.",
        "Import Project Information",
        "Follow the step-by-step guide in the link above to import Project Information.",
        "Import Excel data quickly",
        "Most efficient way to enter data.",
        "Must be using the Export Project Template.",
        "Manually Enter Project Information",
        "Follow the step-by-step guide in the link above to manually enter Project Information.",
        "Manually Enter data",
        "Great way to input a row or two of data.",
      ],
      presentations: [
        "https://docs.google.com/presentation/d/1BdYpR1mK6KBXqYwWxUtp2ti6qI7Ru7dQ/embed?start=true&loop=true&delayms=3000",
        "https://docs.google.com/presentation/d/1WqhzIMfhzYP655-c8rtv4ZEenRgNM68W/embed?start=true&loop=true&delayms=3000",
        "https://docs.google.com/presentation/d/19JJYlkYCoksBsCV2couZ24N4Uviyo-G5/embed?start=true&loop=true&delayms=3000",
      ]
    },
  
    {
      title: "Create Plan Design Sets",
      heading: "What is included in the Plan Design tab?",
      description: "The Plan Design tab holds data related to the design sets for a project. Below is a great example.",
      details: [
        "Design Type",
        "Designer",
        "Plan Set Date",
        "Plan Set Delta",
        "Plan Set Name",
        "Notes",
        "PDF Links",
      ],
    },
  
    {
      subdescription: "Where does the Plan Design data come from?",
      details: [
        "The data comes from the Proposal created for the project. See location here.",
      ],
      //presentations: [insert steps for finding location on proposal]
      
    },
    {
      subdescription: "Copy & Paste Plan Design Sets",
      details: [
        "Follow the step-by-step guide in the link above to copy & paste Plan Design Sets.",
        "Copy & Paste data",
        "Quick and simple way to input data.",
        "The copied data needs to be formatted correctly.",
        "Recommended you use the Export Project Template as a guide.",
      ],
      presentations: [
        "https://docs.google.com/presentation/d/1YaJtow8hPqP1Jz5Dlcu06V9VNj_6uTyJ/embed?start=true&loop=true&delayms=3000",
      ]
    },
    {
      subdescription: "Import Plan Design Sets",
      details: [
        "Follow the step-by-step guide in the link above to import Plan Design Sets.",
        "Import Excel with data",
        "Most efficient way to enter data.",
        "Must be using the Export Project Template.",
      ],
      presentations: [
        "https://docs.google.com/presentation/d/1P577dZYVer35_nm8MrJ-o07WqmE1nBeI/embed?start=true&loop=true&delayms=3000",
      ]
    },
    {
      subdescription: "Manually Enter Plan Design Sets",
      details: [
        "Follow the step-by-step guide in the link above to manually enter the Plan Design Set.",
        "Manually Enter data",
        "Great way to input a row or two of data.",
      ],
      presentations: [
        "https://docs.google.com/presentation/d/1BSSWotDQ_nt7d5gn7nhWd7aTxLHk3Low/embed?start=true&loop=true&delayms=3000"
      ]
    },
  
    {
      title: "Create Specifications",
      heading: "What is included in the Specifications tab?",
      description: "The Specifications tab holds data related to the specifications for a project. Below is a great example.",
      details: [
        "Job Type",
        "Grouping Type",
        "Specifications",
        "Specification Value",
        "Notes",
        "",
        "Where does the Specifications data come from?",
        "The data comes from the Data Pull Excel created for the project. See link here.",
        "",
        "Copy & Paste Specifications",
        "Follow the step-by-step guide in the link above to copy & paste Specifications.",
        "",
        "Copy & Paste data",
        "Quick and simple way to input data.",
        "The copied data needs to be formatted correctly.",
        "Recommended you use the Export Project Template as a guide.",
        "",
        "Import Specifications",
        "Follow the step-by-step guide in the link above to import Specifications.",
        "",
        "Import Excel with data",
        "Most efficient way to enter data.",
        "Must be using the Export Project Template.",
        "",
        "Manually Enter Specifications",
        "Follow the step-by-step guide in the link above to manually enter Specifications.",
        "",
        "Manually Enter data",
        "Great way to input a row or two of data.",
      ],
      presentations: [
        "https://docs.google.com/presentation/d/1AKCOiaRg3mynQpjpWyE2CRvjx7MHjkt9/embed?start=true&loop=true&delayms=3000",
        "https://docs.google.com/presentation/d/17zzlYHebqbqRLXZG5BEZLlKJWJWOdKeY/embed?start=true&loop=true&delayms=3000",
        "https://docs.google.com/presentation/d/1nz01LD1Wt8dnPipBFocQes0vf3KWbEKL/embed?start=true&loop=true&delayms=3000",
      ]
    },
  
    {
      title: "Create Project Checklist",
      heading: "What is included in the Checklist tab?",
      description: "The Checklist tab holds data related to the project checklist. Below is a great example.",
      details: [
        "Checklist Item",
        "Segment One",
        "Segment Description",
        "Required",
        "Created At",
        "Created By",
        "Updated At",
        "Update By",
        "",
        "Where does the Checklist data come from?",
        "The data comes from the Data Pull Excel created for the project. See link here.",
        "",
        "Copy & Paste Checklists",
        "Follow the step-by-step guide in the link above to copy & paste Plan Design Sets.",
        "",
        "Copy & Paste data",
        "Quick and simple way to input data.",
        "The copied data needs to be formatted correctly.",
        "Recommended you use the Export Project Template as a guide.",
        "",
        "Import Plan Checklists",
        "Follow the step-by-step guide in the link above to import Project Information.",
        "",
        "Import Excel with data",
        "Most efficient way to enter data.",
        "Must be using the Export Project Template.",
        "",
        "Manually Enter Checklists",
        "Follow the step-by-step guide in the link above to manually enter the Plan Design Set.",
        "",
        "Manually Enter data",
        "Great way to input a row or two of data.",
      ],
      presentations: [
        "https://docs.google.com/presentation/d/16047U7sjvrCoCTzLCCKtvDYu8X7_1hVF/embed?start=true&loop=true&delayms=3000",
        "https://docs.google.com/presentation/d/1CE5_kPkignxejcPII_XT5vD5xJRiaBez/embed?start=true&loop=true&delayms=3000", 
        "https://docs.google.com/presentation/d/1bW1i_dSGeTyVpeQmapIU-zWZPOvGZ8z6/embed?start=true&loop=true&delayms=3000",
      ]
    },
  
    {
      title: "Create a Budget",
      heading: "What is included in the Budgets tab?",
      description: "The Budgets tab holds data related to the budgets for a project. Below is a great example.",
      details: [
        "Job Type",
        "Budget Revision",
        "Budget Name",
        "Good Through Date",
        "Active Release",
        "Status",
        "Created at",
        "Created by",
        "Updated at",
        "Updated by",
        "",
        "Create and Import Budget ID",
        "Follow the step-by-step guide in the link above to create the Budget ID.",
      ],
      presentations: [
        "https://docs.google.com/presentation/d/1L7TLxUo1QlS63BHftmzo0A5bk3BlRBIW/embed?start=true&loop=true&delayms=3000",
      ]

    },
  
    {
      title: "Proposals Workflow",
      heading: "Create a Proposal",
      description: "The Proposal tab holds data related to the proposals for a project. Below is a great example.",
      details: [
        "What is included in the Proposal tab?",
        "Job Type ID",
        "Revision #",
        "Name",
        "Proposal Date",
        "Effective Date",
        "Effective Through Date",
        "Status",
        "Notes",
        "Active Release",
        "Created At",
        "Created By",
        "Updated At",
        "Updated By",
        "",
        "Where does the Proposal data come from?",
        "The data comes from the Proposal sheet of the Data Pull Excel document created for the project.",
        "",
        "Create and Import Proposal ID",
        "Follow the step-by-step guide in the link above to create a Proposal ID.",
      ],
      presentations: [
        "https://docs.google.com/presentation/d/1UiIQV8soiZwbNf1wDKcrdPPdsB6hXcoQ/embed?start=true&loop=true&delayms=3000",
        ]
    },
    {
        title: "Contracts Workflow",
        heading: "Create a Contract",
        description: "The Contracts tab holds data related to the contracts for a project. Below is a great example.",
        details: [
          "Create Contract ID and Import Contract Items",
          "Follow the step-by-step guide in the link above to create a Contract ID and Import Contract Items.",
          "",
          "Copy & Paste Contract Items",
          "Follow the step-by-step guide in the link above to copy and paste Contract Information.",
          "",
          "Manually Enter Contract Items",
          "Follow the step-by-step guide in the link above to manually enter Contract Information.",
        ],
        presentations: [
            "https://docs.google.com/presentation/d/1nm3Me6AobeYUyTBz7GRBr5S8r--sDxAJ/embed?start=true&loop=true&delayms=3000",
            "https://docs.google.com/presentation/d/1P9kRqMay2mtYMWYt6o6V-1Ae5cqkBu4l/embed?start=true&loop=true&delayms=3000",
            "https://docs.google.com/presentation/d/1SMt52xcP9Upv5GZ9xkqSF6LwXcmhwv7v/embed?start=true&loop=true&delayms=3000",
            ]
      },
      {
        title: "Pre-Built Orders Workflow",
        heading: "Create Pre-built Orders for Warehouse Ordering",
        description: "The Pre-Built Orders tab holds data related to the pre-built orders for a project. Below is a great example.",
        details: [
          "What is included in the Pre-Built Orders tab?",
          "Job Type",
          "Plan",
          "Elevation",
          "Option",
          "Option Code",
          "Number of Pre-Built Orders",
          "",
          "Where does the Pre-Built Orders data come from?",
          "The data comes from the Data Pull Excel created for the project.",
          "",
          "How do I use the Pre-Built Orders tab?",
          "We can view data",
          "We can add data",
          "",
          "Import Pre-Built Order Items",
          "Follow the step-by-step guide in the link above to import Pre-Built Order Items.",
          "",
          "Manually Enter Pre-Built Order Items",
          "Follow the step-by-step guide in the link above to manually enter Pre-Built Order Items.",
        ],
        presentations: [
            "https://docs.google.com/presentation/d/1AsMtz1ck293z8zu2wxosgigo_nUtxNDs/embed?start=true&loop=true&delayms=3000",
            "https://docs.google.com/presentation/d/1-IgH_Sfy--OCP6OqXddrvvqbSoVkDH-3/embed?start=true&loop=true&delayms=3000",
            ]
      },
    {
      title: "Field Quantities",
      heading: "Create Field SF, LF, & others for Field Measurements",
      description: "What is included in the Field Quantities tab?",
      details: [
        "Group",
        "Plan",
        "Elevation",
        "Option",
        "Option Code",
        "Condition",
        "Dimensions",
        "UoM",
        "$/Unit",
        "",
        "Where does the Field Quantities data come from?",
        "The data comes from the Field Quantities sheet of the Data Pull Excel document created for the project.",
        "",
        "How do I use the Field Quantities tab?",
        "We can view data",
        "We can add data",
        "",
        "Import Field Quantities Information",
        "Follow the step-by-step guide in the link above to import Field Quantities information.",
        "",
        "Manually Enter Field Quantities Information",
        "Follow the step-by-step guide in the link above to manually enter Field Quantities information.",
      ],
      presentations: [
        "https://docs.google.com/presentation/d/1nUm4W1bSAQ3SEAnJIOYdGnLFC08aUrEkc-_ODsTgYng/embed?start=true&loop=true&delayms=3000",
        "https://docs.google.com/presentation/d/1Fo7wpIbVeqd53qC1M5CC_RJlkXpIjhi00wBetz9T5Hg/embed?start=true&loop=true&delayms=3000",
        ]
    },
  
    {
      title: "Lots Workflow",
      heading: "Create Lot List",
      description: "The Lots tab holds data related to the lots for a project. Below is a great example.",
      details: [
        "What is included in the Lots tab?",
        "Lot Number",
        "Address",
        "Orientation",
        "Customer Release",
        "FF",
        "PAD",
        "FG",
        "GFF",
        "GL",
        "Sub Job and Job Type",
        "Created At",
        "Created By",
        "Updated At",
        "Updated By",
        "",
        "Where does the Lots data come from?",
        "The data comes from the Lots sheet of the Data Pull Excel document created for the project.",
        "",
        "How do I use the Lots tab?",
        "We can view data",
        "We can add data",
        "We can remove data",
        "",
        "Import Lots Information",
        "Follow the step-by-step guide in the link above to import Lots information.",
        "",
        "Copy & Paste Lots Information",
        "Follow the step-by-step guide in the link above to copy and paste Contract Information.",
        "",
        "Manually Enter Lots Information",
        "Follow the step-by-step guide in the link above to manually enter Lots information.",
      ],
      presentations: [
        "https://docs.google.com/presentation/d/1qbnZPCjB24YEhnD0aOCxHg8Gnbv3VcMq/embed?start=true&loop=true&delayms=3000",
        "https://docs.google.com/presentation/d/1ZFD7nlzHlaYY-CPJSoHu5ceWml3apwuL/embed?start=true&loop=true&delayms=3000",
        "https://docs.google.com/presentation/d/12ublXixcnP0w0E5qgEmGHx8VecOAUOwe/embed?start=true&loop=true&delayms=3000",
        ]
    },
  
    {
      title: "Create a Document",
      heading: "What is included in the Document tab?",
      description: "The Document tab holds data related to the documents for a project. Below is a great example",
      details: [
        "File Name",
        "ID Type",
        "Content Type",
        "Created At",
        "Created By",
        "",
        "What should I upload to the Documents tab?",
        "Any documents unique to the Project; such as House Plan Mix (HPM), Soils Reports, and Requests for Info (RFI).",
        "",
        "Upload Documents",
        "Follow the step-by-step guide in the link above to upload Documents to the Project.",
      ],
      presentations: [
        "https://docs.google.com/presentation/d/14GdES6EWN3Bf-FjbFvxuwyQHE8_7kXZY/embed?start=true&loop=true&delayms=3000",
        ]
    },
  
    {
      title: "Create a Start within the Project View",
      heading: "What is included in the Starts tab?",
      description: "The Starts tab holds data related to the starts for a project. Below is a great example.",
      details: [
        "Job Type",
        "Job Number",
        "Subjob Number",
        "Supervisor",
        "Lots",
        "Status",
        "Start Date",
        "Notes",
        "Created At",
        "Created By",
        "Updated At",
        "Updated By",
        "",
        "Create a Start in Project View",
        "Follow the step-by-step guide in the link above to create a Start.",
      ],
      presentations: [
        "https://docs.google.com/presentation/d/1i3LbtiODKUxoFBuAOS8tQyBiUD1Ov8yX/embed?start=true&loop=true&delayms=3000",
        ]
    },
  
    {
        cardTitle: "Starts",
        heading: "How to Create a Start",
        description: "Watch the video in the link below to create a Start.",
        subdescription: "Start workflow",
        video: "https://www.youtube.com/embed/jGbGXFOk5jY",
    },
  
    {
        title: "Access the Starts List",
        heading: "How to Create a Start",
        description: "Follow the step-by-step guide in the link above to create a Start.",
        subdescription: "How to Add Lots to a Start",
        presentation: "https://docs.google.com/presentation/d/e/2PACX-1vRhMck5RdY5CZSKGLz336XLQxupJCA0Btn97aGokm6M9guvGM8w7zeGeooJN-NwAg/embed?start=false&loop=false&delayms=3000",      
        details: [
          "Follow the step-by-step guide in the link above to add lots to a Start.",
          "How to add Schedule of Values to a Start: Follow the step-by-step guide in the link above to add a Schedule of Value ID to Lots.",
          "How to add Documents to a Start: Follow the step-by-step guide in the link above to add a Schedule of Value ID to Lots.",
          "Now that we’ve made a Start. Let's explore all the data within that start. You can access all the following views by clicking this dropdown menu in your Starts View.",
          "Starts has the following tabs:",
          "Release Info: This tab allows you to add lots to your start. After lots have been added you can assign SOVID’s to those lots. You can also include additional details that may be relevant to your specific lot.",
          "Cost Budget: This tab allows you to see a list of your lots with their attributed total cost. A total sum is also given.",
          "Warehouse Orders: This tab allows you to create and request orders from the warehouse and then to see those orders and change their status.",
          "Documents: This tab allows you to manage documents related to your start.",
        ],
        presentations: [
          "https://docs.google.com/presentation/d/15P_pvcYsWh4pjN_Ri7j5vhRYGTcfhs2_/embed?start=true&loop=true&delayms=3000",
          "https://docs.google.com/presentation/d/15P_pvcYsWh4pjN_Ri7j5vhRYGTcfhs2_/embed?start=true&loop=true&delayms=3000",
          "https://docs.google.com/presentation/d/15P_pvcYsWh4pjN_Ri7j5vhRYGTcfhs2_/embed?start=true&loop=true&delayms=3000",
          "https://docs.google.com/presentation/d/1zEKrHpLy_rqo-ArvxV4N2Ra9DStIfenn/embed?start=true&loop=true&delayms=3000",
        ],
      },
  
      {
        title: "Request Warehouse Orders",
        heading: "Warehouse Orders has the following tabs:",
        description: "Orders Requested",
        subdescription: "By clicking on the dropdown arrow you can see the materials ordered.",
        details: [
          "Request an Order from the Start View",
          "Create an Order ID: Follow the step-by-step guide in the link above to Create an Order ID.",
          "Add Materials to an Order using Pre-Built Orders: Follow the step-by-step guide in the link above to Add Materials to an Order.",
          "Updating Pre-Built Orders within Projects: Follow the step-by-step guide in the link above to Update Pre-Built Orders within Projects.",
          "Confirm Order Request: Follow the step-by-step guide in the link above to Confirm an Order Request.",
        ],
        presentations: [
            "https://docs.google.com/presentation/d/17fYk0uv2YdyU9Y2snnQjbEaivGedwNYIz7FdRbV41Z8/embed?start=true&loop=true&delayms=3000",
            "https://docs.google.com/presentation/d/1rsrtjm1GFBhETGxJ9ADI4MVdb5TN78rPABq5jM9hkYg/embed?start=true&loop=true&delayms=3000",
            "https://docs.google.com/presentation/d/174FhnIoSic55iaFJnyYkxeftSpmA-GnVfcOloF1EbXM/embed?start=true&loop=true&delayms=3000",
            "https://docs.google.com/presentation/d/10A5xMkd6izwEgy15JXo83MMEMQcuZZHmmeHOCOcB2dM/embed?start=true&loop=true&delayms=3000",
            ]
      },
  
      {
        title: "Fulfill Warehouse Orders",
        heading: "Fulfill Warehouse Requested Order",
        description: "Review Requested Order",
        subdescription: "Follow the step-by-step guide in the link above to Review a Requested Warehouse Order.",
        details: [
          "Fulfill Warehouse Order: Follow the step-by-step guide in the link above to Fulfill a Requested Warehouse Order.",
          "Reject Requested Order: Follow the step-by-step guide in the link above to Reject a Requested Warehouse Order.",
        ],
        presentations: [
            "https://docs.google.com/presentation/d/1tcp2Ox3TvMqSu_C4aMDIyMigK-KbluLgqap92kCs5Uc/embed?start=true&loop=true&delayms=3000",
            "https://docs.google.com/presentation/d/1_ZUxeF0V0pnWE8l3qfyJgtUvEF8Zkwf2Mi7xLC81bY8/embed?start=true&loop=true&delayms=3000",
            "https://docs.google.com/presentation/d/1DRFanOoLRUQMKTeUwHodZx9HOFRSxAiiblJKO-A0VuY/embed?start=true&loop=true&delayms=3000",
            ]
      },
  
      {
        title: "Create Order Processing Entry eCMS Import",
        heading: "Review Order",
        description: "Export OrdProcEnt Excel",
        subdescription: "Review OrdProceEnt Excel prior to Importing to eCMS",
        details: [
          "Review Order: Follow the step-by-step guide in the link above to review the order.",
          "Export OrdProcEnt Excel: Follow the step-by-step guide in the link above to export the OrdProcEnt Excel.",
          "Review OrdProceEnt Excel prior to Importing to eCMS: Follow the step-by-step guide in the link above to review the OrdProceEnt Excel prior to importing to eCMS.",
        ],
      },
  
      {
        title: "Office Reports",
        heading: "Office reports has the following tabs:",
        description: "Subjob Creation, Job Cost Report, Contract Items. Let's explore these.",
        subdescription: "Subjob Creation:",
        details: [
          "What data is in Subjob Creation?",
          "Company Number",
          "Customer ID",
          "Labor Sub Account",
          "Department Number",
          "Job Type",
          "Job Number",
          "Job Description",
          "Job City",
          "Subjob Number",
          "Start Date",
          "Geographic Code One",
          "Job Quantity",
          "Income Cost Type",
          "Income Distribution",
          "Contract Number",
          "Responsibility Type E",
          "Responsibility Type PM",
          "Responsibility Type ES",
          "Responsibility Type SE",
          "Published By",
          "Published Date",
        ],
      },
      {
        subdescription: "Job Cost Report:",
        details: [  
          "What Data is in the Job Cost Report?",
          "Company Number",
          "Division Number",
          "Job Number",
          "Subjob Number",
          "Cost Distribution",
          "Cost Type",
          "Budget Hours",
          "Budget Quantity",
          "Budget Amount",
          "Unit of Measure",
          "As Bid Amount",
        ],
      },
  
      {
        subdescription: "Contract Items",
        details: [  
          "What is in Contract Items?",
          "Lot Number",
          "Address",
          "Garage Orientation",
          "Plan Name",
          "Elevation",
          "Option Name",
          "Contract Revision",
          "Contract Quantity",
          "Contract UOM",
          "Contract $/Unit",
          "Contract Amount",
          "Proposal Revision",
          "Proposal Quantity",
          "Proposal UOM",
          "Proposal Amount",
          "Proposal $/Unit",
          "Contract Type Tow Price",
          "Sku#",
        ],
      },
  
      {
        title: "Field Reports",
        heading: "Field Reports has the following tabs:",
        description: "Summary, Coordinator Checklist, Release Info, Slab Printout, Footings Printout, Supervision, Layout, Trenching, Formset, Steel, Footings, Grade, Slab Pour, Non-Billable Service Work",
        subdescription: "",
        details: [
          "Summary",
          "Coordinator Checklist",
          "Release Info",
          "Slab Printout",
          "Footings Printout",
          "Supervision",
          "Layout",
          "Trenching",
          "Formset",
          "Steel",
          "Footings",
          "Grade",
          "Slab Pour",
          "Non-Billable Service Work",
        ],
      },
  
      {
        title: "Assurance",
        heading: "Assurance has the following tabs:",
        description: "",
        subdescription: "Tabs in Assurance:",
        details: [
          "Release Info",
          "Proposal Pricing",
          "Contract Pricing",
          "Proposal Contract Comparison",
          "Staging Order Comparison",
          "Start Items",
          "Documents",
        ],
      },
  
      {
        subdescription: "Assurance Processes",
        details: [
          "How to Compare the Contract with the Proposal: Follow the step-by-step guide in the link above to compare the Contract with the Proposal.",
          "How to update Cost Budget ID: Follow the step-by-step guide in the link above to update Cost Budget ID.",
          "How to update Proposal ID: Follow the step-by-step guide in the link above to update Proposal ID.",
          "How to update Contract ID: Follow the step-by-step guide in the link above to update Contract ID.",
          "How to View or Change the Start Status: Follow the step-by-step guide in the link above to view or change Start Status.",
        ],
        presentations: [
            "https://docs.google.com/presentation/d/1GIz47_qnhl_gHMRVLnMKzrrmKhkxBXxh/embed?start=true&loop=true&delayms=3000",
            "https://docs.google.com/presentation/d/1vXsSTaxZ7UZs4j80v3V0vx_zxe6nnzrYNsSO21NOeuI/embed?start=true&loop=true&delayms=3000",
            "https://docs.google.com/presentation/d/17DJUh6RO5egXDfSS2a86nQ5LQZKJQAJs69UjttsBCiA/embed?start=true&loop=true&delayms=3000",
            "https://docs.google.com/presentation/d/1C8swVhLoVGAYw34chr_yCICyU89s5Cln0O2d2XOIsU0/embed?start=true&loop=true&delayms=3000",
            "https://docs.google.com/presentation/d/1SQg10wzjeNjz8fjgUxXl3U-Oji0hozUa/embed?start=true&loop=true&delayms=3000",
            ]
      },
  
      {
        title: "Create Reports",
        heading: "What is included in the Reports tab?",
        description: "Any Starts data the user wishes to convert to eCMS Imports",
        subdescription: "",
        details: [
          "SubJob Creation",
          "SubJob Creation is used for",
          "Job Cost Import",
          "Job Costing Import is used for importing cost items to the Job Cost Universe within eCMS",
          "Click this LINK to follow a step-by-step guide to run and access Job Cost reports.",
          "Contract Item Import",
          "Contract Item Import is used for importing contracted, revenue items to the Job Cost Universe within eCMS",
          "Click this LINK to follow a step-by-step guide to run and access Contract Item reports.",
          "Order Processing Entry Import",
          "Order Processing Entry Import is used for importing warehouse inventory counts to the {Warehouse Universe} within eCMS",
          "Click this LINK to follow a step-by-step guide to run and access OrdProcEnt reports.",
          "How to Access and Run Reports",
          "Follow the step-by-step guide in the link above to access and run reports.",
        ],
        "presentations": [
            "https://docs.google.com/presentation/d/1AIWPYL5sb8zPQZ8eMUeTK9LoFj8IitM2/embed?start=true&loop=true&delayms=3000",
        ]
      },
      {
        cardTitle: "Miro Board",
      }
    ];