import { useState, useEffect } from "react";
import { useAppSelector } from "../../../app/hooks";
import { pageLinks } from "../../layout/PageLinks";

const usePageLinks = () => {
    const { isAuthenticated, userDetails } = useAppSelector((state: any) => state.auth);
    const [filteredLinks, setFilteredLinks] = useState<any[]>([]);

    useEffect(() => {
        const links = [];
        const userGroups = userDetails?.groups || [];
        // Admin
        if (userGroups.includes('Admin')) {
            links.push(...pageLinks.admin);
        }
        // App
        links.push(...pageLinks.app);
        // Warehouse
        if (userGroups.includes('Warehouse')) {
            links.push(...pageLinks.warehouse);
            setFilteredLinks(links);
            if(!userGroups.includes('Admin')) return;
        }
        // Assurance
        if (userGroups.includes('Assurance')) {
            links.push(...pageLinks.assurance);
        }
        setFilteredLinks(links);
    }, [isAuthenticated, userDetails?.groups]); 

    return filteredLinks;
}

export default usePageLinks;