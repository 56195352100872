const glossary = [
    { cardTitle: "Access", link: "#access" },
    { title: "Logging in to the App", link: "#logging-in-to-the-app" },
    { title: "Logging out of the App", link: "#logging-out-of-the-app" },
    { cardTitle: "Organizational Units", link: "#organizational-units" },
    { title: "Create Company", link: "#create-company" },
    { title: "Create Division", link: "#create-division" },
    { title: "Create Customers", link: "#create-customers" },
    { title: "Create Warehouses", link: "#create-warehouses" },
    { title: "Create Job Types", link: "#create-job-types" },
    { title: "Create Roles", link: "#create-roles" },
    { cardTitle: "Projects", link: "#projects" },
    { title: "Access the Project List", link: "#access-the-project-list" },
    { title: "Create a Project", link: "#create-a-project" },
    { title: "Create Project Information", link: "#create-project-information" },
    { title: "Create Plan Design Sets", link: "#create-plan-design-sets" },
    { title: "Create Specifications", link: "#create-specifications" },
    { title: "Create Project Checklist", link: "#create-project-checklist" },
    { title: "Create a Budget", link: "#create-a-budget" },
    { title: "Proposals Workflow", link: "#proposals-workflow" },
    { title: "Contracts Workflow", link: "#contracts-workflow" },
    { title: "Pre-Built Orders Workflow", link: "#pre-built-orders-workflow" },
    { title: "Field Quantities", link: "#field-quantities" },
    { title: "Lots Workflow", link: "#lots-workflow" },
    { title: "Create a Document", link: "#create-a-document" },
    { cardTitle: "Starts", link: "#starts" },
    { title: "Access the Starts List", link: "#access-the-starts-list" },
    { title: "Request Warehouse Orders", link: "#request-warehouse-orders" },
    { title: "Fulfill Warehouse Orders", link: "#fulfill-warehouse-orders" },
    { title: "Create Order Processing Entry eCMS Import", link: "#create-order-processing-entry-ecms-import" },
    { title: "Office Reports", link: "#office-reports" },
    { title: "Field Reports", link: "#field-reports" },
    { title: "Assurance", link: "#assurance" },
    { title: "Create Reports", link: "#create-reports" },
    { cardTitle: "Miro Board", link: "#miro-board" },
  ];

  export default glossary;