import { useAppSelector } from '../../app/hooks';
import useRouteList from '../../routes/useRouteList';
import { setNavigator } from '../../utils/navigator';
import { Box } from '@mui/material';
import { lazy, Suspense, useEffect } from 'react';
import { useNavigate, Routes, Route, Navigate } from 'react-router-dom';
import { LinearProgress } from '@mui/material';


// Lazy load components
const NotFound = lazy(() => import('../components/NotFound'));
const PrivateRoute = lazy(() => import('../../routes/PrivateRoute'));
const Login = lazy(() => import('../auth/Login'));
const PasswordReset = lazy(() => import('../auth/PasswordReset'));

const MainContent = () => {
    const navigate = useNavigate();
    const { isAuthenticated, isLoading } = useAppSelector((state: any) => state.auth);
    const filteredRoutes = useRouteList();

    useEffect(() => {
        setNavigator(navigate);
    }, [navigate]);

    if (isLoading) {
        return <LinearProgress />;
    }

    return (
        <Box sx={{ height: "100%", width: "100%" }}>
            <Suspense fallback={<LinearProgress sx={{ position: "absolute", height: "100%", width: "100%" }} />}>
                <Routes>
                    {/* Public Routes */}
                    <Route path="/login" element={
                        isAuthenticated ? <Navigate to="/" replace /> : <Login />
                    } />
                    <Route path="/password-reset" element={<PasswordReset />} />

                    {/* Protected Routes */}
                    {isAuthenticated && filteredRoutes?.map(({ component: Component, ...route }) => (
                        <Route
                            key={route.id}
                            path={route.path}
                            element={
                                <PrivateRoute>
                                    <Component />
                                </PrivateRoute>
                            }
                        />
                    ))}

                    {/* Catch-all route */}
                    <Route path="*" element={
                        isAuthenticated ? <NotFound /> : <Navigate to="/login" replace />
                    } />
                </Routes>
            </Suspense>
        </Box>
    );
}

export default MainContent;