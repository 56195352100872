// Project URLs

// Projects
const project_urls = { 
    url: () => '/projects',
    list: () => '/projects',
    findById: (id: string) => `/projects/${id}`,
    getSovItems: (project_id: string, job_type: string) => `/sov/items?project_id=${project_id}&job_type=${job_type}`,
    create: () => "/projects",
    update: (id: string) => `/projects/${id}`,
    delete: (id: string) => `/projects/${id}`,
}
// Project Mappings
const projectMappings = {
    url: () => '/project-mapping',
    list: () => '/project-mapping',
    update: (id: string) => `/project-mapping/${id}`,
    delete: (id: string) => `/project-mapping/${id}`,
}
// Project Notes
const projectInformation = {
    url: () => "/project-information",
    list: () => '/project-information',
    create: () => "/project-information",
    update: (id: string) => `/project-information/${id}`,
    delete: (id: string) => `/project-information/${id}`,
    import: (id: string) => `/projects/information/import?project_id=${id}`,
};
// Plan Design
const planDesigns = {
    url: () => "/plan-designs",
    list: () => '/plan-designs',
    create: () => "/plan-designs",
    update: (id: string) => `/plan-designs/${id}`,
    delete: (id: string) => `/plan-designs/${id}`,
    import: (id: string) => `/plan-designs/import?project_id=${id}`,
};
// Specifications
const specifications = {
    url: () => "/specifications",
    list: () => '/specifications',
    create: () => "/specifications",
    update: (id: string) => `/specifications/${id}`,
    delete: (id: string) => `/specifications/${id}`,
    import: (id: string, job_type_id: string) => `/specifications/import?project_id=${id}&job_type_id=${job_type_id}`,
};
// Checklist
const checklist = {
    url: () => "/project-checklist",
    list: () => '/project-checklist',
    create: () => "/project-checklist",
    update: (id: string) => `/project-checklist/${id}`,
    delete: (id: string) => `/project-checklist/${id}`,
    import: (id: string) => `/project-checklist/import?project_id=${id}`,
};
// Budgets
const budgets = { 
    url: () => "/fnd-budgets",
    list: () => '/fnd-budgets',
    getDetail: (id: string) => `/fnd-budgets?project_id=${id}`,
    getItems: (id: string) => `/fnd-budgets/items?id=${id}`,
    create: (id: string) => `/fnd-budgets?project_id=${id}`,
    update: (id: string) => `/fnd-budgets?id=${id}`,
    delete: (id: string) => `/foundation-budgets/${id}`,
};
// Budget Items
const budgetItems = { 
    url: () => "/foundation-budget-items",
    list: (id: string) => `/fnd-budgets/items?id=${id}`,
    create: (id: string) => `/fnd-budgets/items?id=${id}`,
    import: (id: string) => `/fnd-budgets/import?budget_id=${id}`,
    export: (id: string) => `/fnd-budgets/export?budget_id=${id}`,
    update: (id: string) => `/foundation-budget-items/${id}`,
    delete: (id: string) => `/foundation-budget-items/${id}`,
};

// Proposals
const proposals = { 
    url: () => "/proposals",
    list: () => '/proposals/items/all',
    getDetail: (id: string) => `/proposals?project_id=${id}`,
    createItem: (id: string) => `/proposal/items?proposal_id=${id}`,
    manageItem: (id: string) => `/proposals/items?id=${id}`,
    update: (id: string) => `/proposals?id=${id}`,
    delete: (id: string) => `/proposals?id=${id}`,

}

// Proposal Items
const proposalItems = { 
    url: () => "/proposal-items",
    list: (id: string) => `/proposals/items?proposal_id=${id}`,
    getDetail: (company_number: string, job_number: string) => `/proposals?company_number=${company_number}&job_number=${job_number}`,
    export: (id: string) => `/proposals/items/export?proposal_id=${id}`,
    update: (id: string) => `/proposals/items?id=${id}`,
    delete: (id: string) => `/proposals/items?id=${id}`
}

// Contracts
const contracts = { 
    url: () => "/contracts",
    list: () => '/contracts/items/all',
    getDetail: (id: string) => `/contracts?project_id=${id}`,
    getItems: (id: string) => `/contracts/items?contract_id=${id}`,
    create: (id: string) => `/contract/items?contract_id=${id}`,
    manageItem: (id: string) => `/contracts/items?id=${id}`,
    update: (id: string) => `/contracts?id=${id}`,
    delete: (id: string) => `/contracts?id=${id}`,
}

// Contract Items
const contractItems = { 
    url: () => "/contract-items",
    list: (id: string) => `/contracts/items?contract_id=${id}`,
    export: (id: string) => `/contracts/items/export?contract_id=${id}`,
    update: (id: string) => `/contracts/items?id=${id}`,
    delete: (id: string) => `/contracts/items?id=${id}`,

}
 
// Lots 
const lots = {
    url: () => "/lots",
    list: () => '/lots',
    create: (project_id: string, start_id: string) => `/lots?project_id=${project_id}${start_id ? `&start_id=${start_id}`: ``}`,
    getItems: (id: string) => `/lots?project_id=${id}`,
    update: (id: string) => `/lots?id=${id}`,
    delete: (id: string) => `/lots?id=${id}`,
};

// Flatwork
const fieldQuantities = {
    url: () => '/field-quantities',
    list: () => '/field-quantities',
    create: (project_id:string, job_type_id:string, field_measurement_type_id:string) => `/field-quantities?project_id=${project_id}&job_type_id=${job_type_id}&field_measurement_type_id=${field_measurement_type_id}`,
    update: (id: string) => `/field-quantities/${id}`,
    delete: (id: string) => `/field-quantities/${id}`,
    import: (project_id:string, job_type_id:string, field_measurement_type_id:string) => `/field-quantities/import?project_id=${project_id}&job_type_id=${job_type_id}&field_measurement_type_id=${field_measurement_type_id}`,
  
};

// Prebuilt Orders
const prebuiltOrders = {
    url: () => '/prebuilt-orders',
    list: () => '/prebuilt-orders',
    create: () => `/prebuilt-orders`,
    update: (id: string) => `/prebuilt-orders?id=${id}`,
    delete: (id: string) => `/prebuilt-orders?id=${id}`,
};

// Prebuilt Order Items
const prebuiltOrderItems = {
    url: () => '/prebuilt-orders',
    getDefaultItems: () => `/prebuilt-orders/items/default`,
    create: (order_id: string) => `/prebuilt-orders/items?prebuilt_order_id=${order_id}`,
    update: (id: string) => `/prebuilt-orders/items?id=${id}`,
    delete: (id: string) => `/prebuilt-orders/items?id=${id}`,
};

// Prebuilt Items
const prebuiltItems = {
    url: () => '/prebuilt-items',
    list: () => '/prebuilt-items',
    create: (project_id: string, order_type_id: string) => `/prebuilt-items?project_id=${project_id}&order_type_id=${order_type_id}`,
    import: (project_id: string, job_type_id: string) => `/prebuilt-items/import?project_id=${project_id}&job_type_id=${job_type_id}`,
    update: (id: string) => `/prebuilt-items?id=${id}`,
    delete: (id: string) => `/prebuilt-items?id=${id}`,
    deleteAll: (project_id: string) => `/prebuilt-items?project_id=${project_id}`,
    default: () => `/prebuilt-items/default`,
};

// Schedule of Values
const sov = { 
    url: (project_id: string, _job_type_id: string) => `/sov?project_id=${project_id}`,
    list: () => '/sov',
    getItems: (project_id: string, _job_type_id: string) => `/sov?project_id=${project_id}`,
    create: (project_id: string) => `/sov?project_id=${project_id}`,
    update: (id: string) => `/sov?id=${id}`,
    delete: (id: string) => `/sov?id=${id}`
}

// Schedule of Value Items
const sovItems = { 
    url: (project_id: string, job_type_id: string) => `/sov?project_id=${project_id}&job_type_id=${job_type_id}`,
    update: (id: string) => `/sov?id=${id}`,
    delete: (id: string) => `/sov?id=${id}`
}

// Inclusions/Exclusions
const inclusionsExclusions = {
    url: () => '/inclusions-exclusions',
    list: () => '/inclusions-exclusions',
    create: () => `/inclusions-exclusions`,
    update: (id: string) => `/inclusions-exclusions/${id}`,
    delete: (id: string) => `/inclusions-exclusions/${id}`,
    import: (id: string) => `/inclusions-exclusions/import?project_id=${id}`,
}

// RFI
const rfi = {
    url: () => '/request-for-information',
    list: () => '/request-for-information',
    create: () => `/request-for-information`,
    update: (id: string) => `/request-for-information/${id}`,
    delete: (id: string) => `/request-for-information/${id}`,
}

// Project Endpoints
const project_endpoints = {
    project_urls,
    projectMappings,
    projectInformation,
    planDesigns,
    specifications,
    checklist,
    budgets,
    budgetItems,
    proposals,
    proposalItems,
    contracts,
    contractItems,
    lots,
    fieldQuantities,
    prebuiltOrders,
    prebuiltOrderItems,
    prebuiltItems,
    sov,
    sovItems,
    inclusionsExclusions,
    rfi,
}

export default project_endpoints;