import Cookies from 'js-cookie';
import { fetchAuthSession } from 'aws-amplify/auth';
import appAPI from "../api/appAPI";

const authService = {

    currentSession: async () => {
        try {
            const { tokens } = await fetchAuthSession({ forceRefresh: true });
            if (!tokens?.accessToken || !tokens?.idToken) {
                throw new Error('No valid tokens found');
            }
            return tokens;
        } catch (err) {
            console.error('Error getting session:', err);
            throw err;
        }
    },
    getCurrentUser: async () => {
        const response = await appAPI.get('/auth/current-user');
        if (!response.data) throw new Error('No current user');
    
        return response.data;
    },
    clearSession: () => {
        const userPoolClientId = process.env.REACT_APP_AWS_CLIENT_ID;
        // Get the last auth user
        const cookiePrefix = `CognitoIdentityServiceProvider.${userPoolClientId}`;
        const lastAuthUser = Cookies.get(`${cookiePrefix}.LastAuthUser`);
        
        if (lastAuthUser) {
            const userPrefix = `${cookiePrefix}.${lastAuthUser}`;
            // Remove all related cookies
            Cookies.remove(`${userPrefix}.idToken`);
            Cookies.remove(`${userPrefix}.accessToken`);
            Cookies.remove(`${userPrefix}.refreshToken`);
            Cookies.remove(`${cookiePrefix}.LastAuthUser`);
        }
    }

};

export default authService;