import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useNavigate } from 'react-router-dom';
import { logout, reset } from '../../auth/authSlice';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutSharpIcon from '@mui/icons-material/LogoutSharp';
import { Typography, useTheme } from '@mui/material';
import { toggleSidebar } from '../../layout/layoutSlice';
import usePageLinks from '../../components/hooks/usePageLinks';
import styles from './styles.module.css';

const widthOpen = 300;
const widthClosed = 0;

export default function SideNavbar(_props: any) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isAuthenticated, suiteDetailsConfirmed } = useAppSelector((state: any) => state.auth);
  const { isSidebarOpen } = useAppSelector((state: any) => state.layout);
  const [drawerWidth, setDrawerWidth] = useState(0);
  const theme = useTheme();
  const pages = usePageLinks();

  useEffect(() => {
    
    const width = isSidebarOpen ? widthOpen : widthClosed;
    setDrawerWidth(width);
  }, [isAuthenticated, isSidebarOpen]);

  const handleLogout = async () => {
    await dispatch(logout());
    dispatch(reset());
    navigate('/login');
  };

  const handleNavigation = (path: string) => {
    dispatch(toggleSidebar());
    navigate(path);
  };

  if (!suiteDetailsConfirmed) {
    return null;
  }

  // Define CSS variables for dynamic styles
  const drawerBgColor = theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.primary.dark;
  const drawerTextColor = theme.palette.primary.light;
  const drawerBorderColor = theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark;
  const transitionDuration = theme.transitions.duration.enteringScreen;
  const transitionEasing = theme.transitions.easing.easeIn;

  return (
    <Drawer
      className={styles.drawer}
      sx={{
        '--drawer-width': `${drawerWidth}px`,
        '--drawer-bg-color': drawerBgColor,
        '--drawer-text-color': drawerTextColor,
        '--drawer-border-color': drawerBorderColor,
        '--transition-duration': `${transitionDuration}ms`,
        '--transition-easing': transitionEasing,
      }}
      variant="persistent"
      anchor="left"
      open={isSidebarOpen}
      classes={{
        paper: styles.drawerPaper,
      }}
    >
      {/* Main Content (Navigation Items) */}
      <List sx={{ flexGrow: 1, overflowY: 'auto' }}>
        {pages?.map((page) => (
          <React.Fragment key={page?.id}>
            <Box sx={{ display: 'flex', alignItems: 'center', height: '64px', width: '100%', opacity: isSidebarOpen ? 1 : 0, padding: 1 }}>
              <ListItem component="label" disablePadding>
                <ListItemButton onClick={() => handleNavigation(page?.link)}>
                  <ListItemIcon sx={{ color: theme.palette.primary.light, ml: 1 }}>{page?.icon}</ListItemIcon>
                  <Box
                    sx={{ display: 'flex', flexDirection: 'column', width: '100%', textAlign: 'start' }}
                    className={isSidebarOpen ? styles.fadeIn : styles.fadeOut}
                  >
                    <ListItemText
                      sx={{ color: theme.palette.primary.light, ml: 1 }}
                      primary={
                        <Typography variant="body1" color={theme.palette.primary.light} sx={{ fontSize: '1rem', fontWeight: 700 }}>
                          {page?.title}
                        </Typography>
                      }
                    />
                  </Box>
                </ListItemButton>
              </ListItem>
            </Box>
            <Divider sx={{ backgroundColor: theme.palette.primary.light}} />
          </React.Fragment>
        ))}
        <Box sx={{ opacity: isSidebarOpen ? 1 : 0, height: '64px', padding: 1 }}>
          <ListItem key="logout" disablePadding>
            <ListItemButton onClick={handleLogout}>
              <ListItemIcon sx={{ color: theme.palette.primary.light, ml: 1 }}>
                <LogoutSharpIcon fontSize="large" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography sx={{ fontWeight: 700, ml: 1, fontSize: '1rem', color: theme.palette.primary.light }}>
                    Logout
                  </Typography>
                }
              />
            </ListItemButton>
          </ListItem>
        </Box>
      </List>     
    </Drawer>
  );
}