// Warehouse Endpoints

// Materials
const materials = {
    url: () => '/warehouse-materials',
    list: () => '/warehouse-materials',
    create: () => '/warehouse-materials',
    update: (id: string) => `/warehouse-materials/${id}`,
    delete: (id: string) => `/warehouse-materials/${id}`,
};

// Warehouse Orders
const warehouseOrders = {
    url: () => "/warehouse/orders",
    list: () => "/warehouse/orders",
    create: (start_id: string) => `/warehouse/orders?start_id=${start_id}`,
    export: (id: string) => `/reports/ordprocent/export?order_id=${id}`,
    update: (id: string) => `/warehouse/orders?id=${id}`,
    delete: (id: string) => `/warehouse/orders?id=${id}`,
};

// Warehouse Orders by Start ID
const warehouseOrdersByStartID = {
    url: () => '/warehouse/orders/starts',
    list: () => '/warehouse/orders/starts',
    create: (start_id: string) => `/warehouse/orders?start_id=${start_id}`,
    update: (id: string) => `/warehouse/orders?id=${id}`,
    delete: (id: string) => `/warehouse/orders?id=${id}`,
};

// Warehouse Order Items
const warehouseOrderItems = {
    url: () => "/warehouse/orders/items",
    list: () => "/warehouse/orders/items",
    getItems: (params: any) => `/warehouse/orders?start_id=${params?.id}`,
    getDefaultItems: () => `/warehouse/orders/items/default`,
    create: (order_id: string) => `/warehouse/orders/items?order_id=${order_id}`,
    update: (id: string) => `/warehouse/orders/items?id=${id}`,
    delete: (id: string) => `/warehouse/orders/items?id=${id}`,
};

// Warehouse Order - Warehouse View
const warehouseOrdersDefault = {
    url: () => '/warehouse/orders/items/default',
    list: () => '/warehouse/orders/items/default',
    create: (start_id: string) => `/warehouse/orders?start_id=${start_id}`,
    update: (id: string) => `/warehouse/orders?id=${id}`,
    delete: (id: string) => `/warehouse/orders?id=${id}`,
};

// Warehouse Order Items - Warehouse View
const warehouseOrderItemsDefault = {
    url: () => "/warehouse/orders/items",
    list: () => "/warehouse/orders/items",
    getItems: (params: any) => `/warehouse/orders?start_id=${params?.id}`,
    create: (order_id: string) => `/warehouse/orders/items?order_id=${order_id}`,
    update: (id: string) => `/warehouse/orders/items?id=${id}`,
    delete: (id: string) => `/warehouse/orders/items?id=${id}`,
};


// Warehouses
const warehouses = {
    url: () => "/warehouses",
    list: () => "/warehouses",
    create: () => "/warehouses",
    update: (id: string) => `/warehouses/${id}`,
    delete: (id: string) => `/warehouses/${id}`,
};

const warehouse_endpoints = {
    materials,
    warehouseOrders,
    warehouseOrdersByStartID,
    warehouseOrderItems,
    warehouseOrdersDefault,
    warehouseOrderItemsDefault,
    warehouses,
}

export default warehouse_endpoints;