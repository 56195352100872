// Tag types for rtk query tags
export const TagTypes = {
  //Admin
  ADMIN: 'Admin',
  ADMIN_GROUPS: 'AdminGroups',
  ADMIN_USER_GROUPS: 'AdminUserGroups',
  //User
  USER: 'User',
  //Data
  DATA: 'Data',
  //Documents
  DOCUMENTS: 'Documents',
  //Dropdowns
  DROPDOWNS: 'Dropdowns',
  //Org Units
  COMPANIES: 'Companies',
  DIVISIONS: 'Divisions',
  TRADES: 'Trades',
  JOB_TYPES: 'JobTypes',
  CUSTOMERS: 'Customers',
  SUPERVISORS: 'Supervisors',
  ESTIMATORS: 'Estimators',
  ACCOUNTANTS: 'Accountants',
  FOREMEN: 'Foremen',
  EMPLOYEES: 'Employees',
  COST_CODES: 'CostCodes',
  COST_TYPES: 'CostTypes',
  COST_CODE_SEGMENT_ONE: 'CostCodeSegmentOne',
  COST_CODE_SEGMENT_TWO: 'CostCodeSegmentTwo',
  ORDER_TYPES: 'OrderTypes',
  CONDITIONS: 'Conditions',
  FIELD_MEASUREMENT_TYPES: 'FieldMeasurementTypes',
  //Project
  PROJECTS: 'Projects',
  PROJECT_MAPPINGS: 'ProjectMappings',
  INFORMATION: 'Information',
  PLANDESIGN: 'PlanDesign',
  SPECIFICATIONS: 'Specifications',
  COORDINATOR_CHECKLIST: 'CoordinatorChecklist',
  CHECKLIST: 'Checklist',
  BUDGETS: 'Budgets',
  BUDGET_SUB_UNITS: 'BudgetSubUnits',
  BUDGET_ITEMS: 'BudgetItems',
  PROPOSALS: 'Proposals',
  PROPOSAL_ITEMS: 'ProposalItems',
  CONTRACTS: 'Contracts',
  CONTRACT_ITEMS: 'ContractItems',
  PREBUILT_ORDERS: 'PrebuiltOrders',
  PREBUILT_ORDER_ITEMS: 'PrebuiltOrderItems',
  PREBUILT_ITEMS: 'PrebuiltItems',
  INCLUSIONS_EXCLUSIONS: 'InclusionsExclusions',
  RFI: 'RFI',
  FIELD_QUANTITIES: 'FieldQuantities',
  LOTS: 'Lots',
  SOV: 'SoV',
  SOV_ITEMS: 'SoVItems',
  //Starts
  STARTS: 'Starts',
  START_ITEMS: 'StartItems',
  START_ITEMS_BY_LOT_ID: 'StartItemsByLotID',
  STARTS_STATUS: 'StartsStatus',
  STARTS_BY_LOT_ID: 'StartsByLotID',
  FIELD_MEASUREMENTS: 'FieldMeasurements',
  FIELD_MEASUREMENT_ITEMS: 'FieldMeasurementItems',
  //Warehouse Orders
  WAREHOUSES: 'Warehouses',
  WAREHOUSE_ORDERS: 'WarehouseOrders',
  WAREHOUSE_MATERIALS: 'WarehouseMaterials',  
  WAREHOUSE_ORDERS_BY_START_ID: 'WarehouseOrdersByStartID',
  WAREHOUSE_ORDER_ITEMS: 'WarehouseOrderItems',
  WAREHOUSE_ORDERS_DEFAULT: 'WarehouseOrdersDefault',
  WAREHOUSE_ORDER_ITEMS_DEFAULT: 'WarehouseOrderItemsDefault',
  //Assurance
  ASSURANCE: 'Assurance',
  ASSURANCE_DETAIL: 'AssuranceDetail',
  PROPOSAL_CONTRACT_COMPARISON: 'ProposalContractComparison',
  FUTURE_ORDERS: 'FutureOrders',
  STAGING_ORDERS: 'StagingOrders',
  STAGING_ORDER_COMPARISON: 'StagingOrderComparison',
  //Reports
  REPORTS: 'Reports',
  FIELD_REPORTS: 'FieldReports',
} as const;

export type TagType = typeof TagTypes[keyof typeof TagTypes];

export interface TagPart {
  type: TagType;
  id?: string;
}

export const createTag = (...parts: (TagPart | undefined)[]) => {
  return parts
    .filter((part): part is TagPart => part !== undefined)
    .map(part => `${part.type}${part.id ? `:${part.id}` : ''}`)
    .join('/');
};

export const parseTag = (tag: string): TagPart[] => {
  return tag.split('/').map(part => {
    const [type, id] = part.split(':');
    return { type: type as TagType, id };
  });
};
