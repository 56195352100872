

// Admin
const admin = {
    url: () => "/admin",
    list: () => '/admin/users',
    create: () => '/admin/users',
    delete: (row: any) => `/admin/users?username=${row.username}`,
};

const adminGroups = {
    url: () => "/admin/groups/users",
    list: () => '/admin/groups/users',
    create: () => '/admin/groups',
    update: (row: any) => `/admin/groups?groupName=${row.groupName}`,
    delete: (row: any) => `/admin/groups?groupName=${row.groupName}`,
}

const adminUserGroups = {
    url: () => "/admin/groups/users",
    list: () => '/admin/groups/users',
    create: (row: any) => `/admin/groups/users?username=${row.username}&groupName=${row.groupName}`,
    delete: (row: any) => `/admin/groups/users?username=${row.username}&groupName=${row.groupName}`,
};

const adminSession = {
    url: () => "/admin/session",
    list: () => '/admin/session',
    create: () => '/admin/session',
    delete: () => '/admin/session',
}

const adminEndpoints = {
    admin,
    adminGroups,
    adminUserGroups,
    adminSession
}

export default adminEndpoints;