import { createSlice } from "@reduxjs/toolkit";

interface WarehouseState {
    warehouses: any[];
    selectedWarehouse: any;
    isLoading: boolean;
    error: string | null;
}

const initialState: WarehouseState = {
    warehouses: [],
    selectedWarehouse: null,
    isLoading: false,
    error: null,
};

export const warehouseSlice = createSlice({
    name: 'warehouse',
    initialState,
    reducers: {
        setSelectedWarehouse: (state, action) => {
            state.selectedWarehouse = action.payload || null;
        },
    }
});

export const { setSelectedWarehouse } = warehouseSlice.actions;
export default warehouseSlice.reducer;