// Assurance Endpoints

const assurance = {
    url: () => '/assurance',
    list: () => '/assurance',
    create: () => '/assurance',
    update: (id: string) => `/assurance/${id}`,
    delete: (id: string) => `/assurance/${id}`,
};

const futureOrders = {
    url: () => '/future-orders',
    list: () => '/future-orders',
    import: () => '/future-orders/import',
    create: () => '/future-orders',
    update: (id: string) => `/future-orders/${id}`,
    delete: (id: string) => `/future-orders/${id}`,
};

// Staging Orders
const stagingOrders = {
    url: () => '/staging-orders',
    list: () => '/staging-orders',
    create: () => '/staging-orders',
    update: (id: string) => `/staging-orders/${id}`,
    delete: (id: string) => `/staging-orders/${id}`,
};

const assurance_endpoints = {
    assurance,
    futureOrders,
    stagingOrders,
}

export default assurance_endpoints;