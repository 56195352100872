import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define interfaces for the color palette
interface ColorVariant {
  light: string;
  main: string;
  dark: string;
}

interface TextColors {
  primary: string;
  secondary: string;
}

interface ThemeColors {
  primary: ColorVariant;
  secondary: Partial<ColorVariant> & { main: string };
  success: Partial<ColorVariant> & { main: string };
  error: Partial<ColorVariant> & { main: string };
  background: string;
  text: TextColors;
}

interface Colors {
  light: ThemeColors;
  dark: ThemeColors;
}

const colors: Colors = {
  light: {
    primary: {
      light: '#FFFFFF',
      main: '#2278B2', 
      dark: '#15263C',
    },
    secondary: {
      main: '#FAAA3E',
    },
    success: {
      light: '#E4E9E6',
      main: '#22B278',
      dark: '#15263C',
    },
    error: {
      light: '#E9E4E8',
      main: '#B22278',
      dark: '#3C1526',
    },
    background: '#F7F7F7',
    text: {
      primary: '#15263C',
      secondary: '#15263C',
    },
  },
  dark: {
    primary: {
      light: '#F7F7F7',
      main: '#1282A2',
      dark: '#15263C',
    },
    secondary: {
      main: '#FFA62B',
    },
    success: {
      light: '#3A6B50',
      main: '#12A282',
      dark: '#0A2811',
    },
    error: {
      light: '#6B3A50',
      main: '#A21282',
      dark: '#280A11',
    },
    background: '#121212',
    text: {
      primary: '#FFFFFF',
      secondary: '#E0E0E0',
    },
  },
};

// Define the theme config type (for Material-UI)
interface ThemeConfig {
  palette: {
    mode: 'light' | 'dark';
    primary: ColorVariant & { contrastText: string };
    secondary: Partial<ColorVariant> & { main: string; contrastText: string };
    success: Partial<ColorVariant> & { main: string };
    text: TextColors;
    background: { default: string };
  };
  typography: {
    fontFamily: string;
    h1: { color: string };
    h2: { color: string };
    h3: { color: string };
    body1: { color: string };
    body2: { color: string };
  };
  components: {
    MuiButton?: {
      styleOverrides: {
        root: { borderRadius: number };
        contained: { backgroundColor: string; color: string; '&:hover': { backgroundColor: string } };
        outlined: { borderColor: string; color: string; '&:hover': { borderColor: string; color: string } };
      };
    };
    MuiCheckbox?: { styleOverrides: { root: { color: string } } };
    MuiAppBar?: { styleOverrides: { root: { backgroundColor: string; color: string } } };
    MuiIconButton?: { styleOverrides: { root: { color: string } } };
    MuiDrawer?: { styleOverrides: { paper: { backgroundColor: string; color: string } } };
    MuiDataGrid?: {
      styleOverrides: {
        columnHeaders: { backgroundColor: string; color: string; fontWeight: string };
        cell: { color: string; '&:focus': { outline: string } };
      };
    };
    MuiTabLabel?: { styleOverrides: { root: { color: string; fontWeight: number } } };
    MuiSelect?: { styleOverrides: { root: { color: string }; select: { fontWeight: number } } };
    // MuiTextField?: {
    //   styleOverrides: {
    //     root: {
    //       '& .MuiOutlinedInput-root': {
    //         '& fieldset': { borderColor: string, borderWidth: string, fontWeight: number };
    //         '&:hover fieldset': { borderColor: string, borderWidth: string, fontWeight: number };
    //         '&.Mui-focused fieldset': { borderColor: string, borderWidth: string, fontWeight: number };
    //         '& input': { color: string, fontWeight: number };
    //         '& .MuiInputLabel-root': { fontWeight: number };
    //       };
    //     };
    //   };
    // };
    MuiMenuItem?: { styleOverrides: { root: { color: string; fontWeight: number } } };
    MuiPickersToolbar?: {
      styleOverrides: {
        root: {
          color: string;
          borderRadius: number;
          borderWidth: number;
          borderColor: string;
          border: string;
          backgroundColor: string;
          padding: string;
          '& .MuiTypography-root': { fontSize: string; fontWeight: number; color: string };
        };
      };
    };
    MuiPickersDay?: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          margin: '2px',
          width: '40px',
          height: '40px',
        };
      };
    };
    MuiCalendarPicker?: {
      styleOverrides: {
        root: {
          width: '320px',
          '& .MuiDayPicker-header': {
            fontSize: '1rem',
            fontWeight: 600,
          },
        };
      };
    };
    MuiMonthPicker?: {
      styleOverrides: {
        root: {
          width: '320px',
          '& .MuiPickersMonth-root': {
            fontSize: '1rem',
            fontWeight: 600,
          },
        };
      };
    };
    MuiYearPicker?: {
      styleOverrides: {
        root: {
          width: '320px',
          '& .MuiPickersYear-root': {
            fontSize: '1rem',
            fontWeight: 600,
          },
        };
      };
    };
    MuiDataGridFooter?: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        };
      };
    };
  };
}

// Define the layout state type
interface LayoutState {
  isSidebarOpen: boolean;
  isMobile: boolean;
  selectedSuite: string;
  layoutStyle: {
    margin: string;
    height: string;
    width: string;
  };
  themeMode: 'light' | 'dark';
  themeConfig: ThemeConfig;
}

// Theme config factory function
const themeConfigFactory = (themeMode: 'light' | 'dark' = 'light'): ThemeConfig => ({
  palette: {
    mode: themeMode,
    primary: {
      light: colors[themeMode].primary.light,
      main: colors[themeMode].primary.main,
      dark: colors[themeMode].primary.dark,
      contrastText: '#fff',
    },
    secondary: {
      light: colors[themeMode].primary.light,
      main: colors[themeMode].secondary.main,
      dark: colors[themeMode].primary.dark,
      contrastText: colors[themeMode].text.primary,
    },
    success: {
      main: colors[themeMode].success.main,
    },
    text: {
      primary: colors[themeMode].text.primary,
      secondary: colors[themeMode].text.secondary,
    },
    background: {
      default: colors[themeMode].background,
    },
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
    h1: { color: colors[themeMode].text.secondary },
    h2: { color: colors[themeMode].text.secondary },
    h3: { color: colors[themeMode].text.secondary },
    body1: { color: colors[themeMode].text.secondary },
    body2: { color: colors[themeMode].text.secondary },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: { borderRadius: 4 },
        contained: {
          backgroundColor: colors[themeMode].primary.main,
          color: colors.dark.text.primary,
          '&:hover': { backgroundColor: colors[themeMode].primary.dark },
        },
        outlined: {
          borderColor: colors[themeMode].primary.main,
          color: colors[themeMode].text.primary,
          '&:hover': {
            borderColor: colors[themeMode].primary.dark,
            color: themeMode === 'light' ? colors[themeMode].primary.light : colors[themeMode].primary.dark,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: { root: { color: colors[themeMode].primary.main } },
    },
    MuiAppBar: {
      styleOverrides: { root: { backgroundColor: colors[themeMode].primary.dark, color: colors[themeMode].text.primary } },
    },
    MuiIconButton: {
      styleOverrides: { root: { color: colors[themeMode].text.primary } },
    },
    MuiDrawer: {
      styleOverrides: { paper: { backgroundColor: colors[themeMode].primary.dark, color: colors[themeMode].text.primary } },
    },
    MuiTabLabel: {
      styleOverrides: { root: { color: colors[themeMode].text.primary, fontWeight: 700 } },
    },
    MuiDataGrid: {
      styleOverrides: {
        columnHeaders: {
          backgroundColor: colors[themeMode].primary.light,
          color: colors[themeMode].text.primary,
          fontWeight: 'bold',
        },
        cell: {
          color: colors[themeMode].text.primary,
          '&:focus': { outline: `2px solid ${colors[themeMode].primary.main}` },
        },
      },
    },
    MuiDataGridFooter: {
      styleOverrides: { root: { fontSize: '1rem' } },
    },
    MuiSelect: {
      styleOverrides: {
        root: { color: colors[themeMode].text.primary },
        select: { fontWeight: 500 },
      },
    },
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //       '& .MuiOutlinedInput-root': {
    //         '& fieldset': { 
    //           borderColor: colors[themeMode].primary.dark,
    //           borderWidth: '1px',
    //           fontWeight: 500,
    //         },
    //         '&:hover fieldset': { 
    //           borderColor: colors[themeMode].primary.dark,
    //           borderWidth: '1px',
    //           fontWeight: 500,
    //         },
    //         '&.Mui-focused fieldset': { 
    //           borderColor: colors[themeMode].primary.main, 
    //           borderWidth: '1px',
    //           fontWeight: 500,
    //         },
    //         '& input': { 
    //           color: colors[themeMode].text.primary,
    //           fontWeight: 500,
    //         },
    //         '& .MuiInputLabel-root': {
    //           fontWeight: 500,
    //         },
    //       },
    //     },
    //   },
    // },
    MuiMenuItem: {
      styleOverrides: { root: { color: colors[themeMode].text.primary, fontWeight: 500 } },
    },
    MuiPickersToolbar: {
      styleOverrides: {
        root: {
          color: '#1565c0',
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#2196f3',
          border: '1px solid',
          backgroundColor: '#bbdefb',
          padding: '16px',
          '& .MuiTypography-root': {
            fontSize: '1.2rem',
            fontWeight: 700,
            color: '#1565c0',
          },
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          margin: '2px',
          width: '40px',
          height: '40px',
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          width: '320px',
          '& .MuiDayPicker-header': {
            fontSize: '1rem',
            fontWeight: 600,
          },
        },
      },
    },
    MuiMonthPicker: {
      styleOverrides: {
        root: {
          width: '320px',
          '& .MuiPickersMonth-root': {
            fontSize: '1rem',
            fontWeight: 600,
          },
        },
      },
    },
    MuiYearPicker: {
      styleOverrides: {
        root: {
          width: '320px',
          '& .MuiPickersYear-root': {
            fontSize: '1rem',
            fontWeight: 600,
          },
        },
      },
    },
  },
});

// Initial state
const initialState: LayoutState = {
  isSidebarOpen: false,
  isMobile: false,
  selectedSuite: "",
  layoutStyle: {
    margin: "0 auto",
    height: "100%",
    width: "100%",
  },
  themeMode: 'light',
  themeConfig: themeConfigFactory('light'),
};

// Create the slice
export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    openSidebar: (state) => {
      state.isSidebarOpen = true;
      state.layoutStyle = {
        ...state.layoutStyle,
        width: state.isSidebarOpen && !state.isMobile ? 'calc(100vw - 300px)' : '100vw',
      };
    },
    closeSidebar: (state) => {
      state.isSidebarOpen = false;
      state.layoutStyle = { ...state.layoutStyle, width: '100vw' };
    },
    toggleSidebar: (state) => {
      state.isSidebarOpen = !state.isSidebarOpen;
      state.layoutStyle = {
        ...state.layoutStyle,
        width: state.isSidebarOpen && !state.isMobile ? 'calc(100vw - 300px)' : '100vw',
      };
    },
    setSelectedSuite: (state, action: PayloadAction<string>) => {
      state.selectedSuite = action.payload;
    },
    toggleThemeMode: (state) => {
      state.themeMode = state.themeMode === 'light' ? 'dark' : 'light';
      state.themeConfig = themeConfigFactory(state.themeMode);
    },
    setIsMobile: (state, action: PayloadAction<boolean>) => {
      state.isMobile = action.payload;
    },
  },
});

// Export actions and reducer
export const { openSidebar, closeSidebar, toggleSidebar, toggleThemeMode, setIsMobile, setSelectedSuite } = layoutSlice.actions;

export default layoutSlice.reducer;