import { FormControl, InputLabel, Select } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { useState, useMemo, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate, useLocation } from 'react-router-dom';
import { URLS } from '../../../routes/urls/urls';
import { ReactComponent as LogoReversed } from '../../../assets/shepherd-logomark-reversecolor.svg';
import { setSuiteDetails } from "../../auth/authSlice";
import { useGetCompanyNumbersQuery } from "../../dropdowns/dropdownApi";
import { useGetDivisionNumbersQuery } from "../../dropdowns/dropdownApi";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { useGetDataQuery } from "../../api/apiSliceV2";
import { TagTypes } from "../../api/tagTypes";
import endpoints from "../../../routes/endpoints/endpoints";
import { setSelectedWarehouse } from "../../warehouse/warehouseSlice";

const useRenderMenus = (props: {
    pages: any[],
    styles: any,
    isOrgUnitRoute: boolean,
    isSidebarOpen: boolean,
    anchorElNav: null | HTMLElement,
    onClose: () => void,
    toggleSidebar: () => void,
    }) => {
    const {
        pages,
        styles,
        isOrgUnitRoute,
        isSidebarOpen,
        anchorElNav,
        onClose,
        toggleSidebar,
    } = props;

    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width: 600px)');
    const dispatch = useAppDispatch();
    const { company, division } = useAppSelector((state: any) => state.auth);
    const selectedWarehouse = useAppSelector((state: any) => state.warehouse.selectedWarehouse);
    const [companySelection, setCompanySelection] = useState<any>({});
    const [divisionSelection, setDivisionSelection] = useState<any>({});
    const [warehouseSelection, setWarehouseSelection] = useState<any>(selectedWarehouse || {});
    const { data: company_numbers } = useGetCompanyNumbersQuery(undefined, {refetchOnMountOrArgChange: true});

    const params = useMemo(() => ({ company_id: companySelection?._id }), [companySelection]);
    const { data: division_numbers } = useGetDivisionNumbersQuery(params, {refetchOnMountOrArgChange: true} );

    const warehouse_params = useMemo(() => ({ company_id: companySelection?._id }), [companySelection]);
    const {data: warehouses} = useGetDataQuery({
        url: endpoints[TagTypes.WAREHOUSES].list(),
        params: warehouse_params,
    }, {skip: !companySelection?._id, refetchOnMountOrArgChange: true});

    const isWarehouseRoute = useMemo(() => location.pathname.includes(URLS.warehouseOrders), [location.pathname]);

    useEffect(() => {
        if (company && division) {
          setCompanySelection(company);
          setDivisionSelection(division);
          dispatch(setSuiteDetails({ company: company, division: division }));
        }
      }, [company, division]);

    useEffect(() => {
        if (companySelection && Object.keys(companySelection).length > 0 && divisionSelection && Object.keys(divisionSelection).length > 0) {
          dispatch(setSuiteDetails({ company: companySelection, division: divisionSelection }));
        }
    }, [companySelection, divisionSelection, dispatch]);

    useEffect(() => {
        if (selectedWarehouse) {
            setWarehouseSelection(selectedWarehouse);
        }
    }, [selectedWarehouse]);

    const handleCloseNavMenu = () => {
        onClose();
    };

    const handleCompanyNumberChange = (event: any) => {
        if (event?.target?.value) {
            setCompanySelection(event.target.value);
            setDivisionSelection({});
            setWarehouseSelection({});
            dispatch(setSelectedWarehouse(null));
            navigate('/');
        };
    };
    
    const handleDivisionNumberChange = (event: any) => {
        if (event?.target?.value) {
            setDivisionSelection(event.target.value);
        };
    };

    const handleLogoClick = () => {
        navigate(URLS.root);
    };

    const renderMobileMenu = useMemo(() => {
        if (!isMobile) {
            return null;
        }

        if(location.pathname === URLS.userGuide) {
            return (
                <Box className={styles.logoContainer} sx={{padding: 1}} onClick={handleLogoClick}>
                    <LogoReversed
                        className={styles.logo}
                        style={{
                            fill: theme.palette.primary.light,
                            width: 'auto',
                            height: 52,
                            transition: 'opacity 0.2s ease',
                        }}
                    />
                </Box>
            );
        }

        return (
            <Box 
            sx={{
                display: 'inline-flex',
                alignItems: 'center',
                cursor: 'pointer',
                width: 'auto',
                height: '100%',
                px: 1,
                '&:hover': { opacity: 0.8 },
              }}>
                <Box className={styles.logoContainer} sx={{padding: 1}} onClick={handleLogoClick}>
                    <LogoReversed
                        className={styles.logo}
                        style={{
                            fill: theme.palette.primary.light,
                            width: 'auto',
                            height: 52,
                            transition: 'opacity 0.2s ease',
                        }}
                    />
                </Box>
                
                <Box className={styles.mobileMenuBox}>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav} 
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        keepMounted
                        open={Boolean(anchorElNav) && isSidebarOpen}
                        onClose={handleCloseNavMenu}
                        sx={{
                            maxHeight: '60vh', 
                            width: 'auto', 
                            marginTop: '10px',
                            
                        }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                        {!isOrgUnitRoute && (
                            <MenuItem>
                            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                                <FormControl className={styles.formControlMobile}>
                                    <InputLabel className={styles.selectLabel}>Company</InputLabel>
                                    <Select
                                    value={
                                        company_numbers?.data?.some((item: any) => item.company_number === companySelection?.company_number)
                                        ? companySelection?.company_number
                                        : ''
                                    }
                                    label="Company"
                                    size="small"
                                    onChange={(event) => {
                                        const selectedCompany = company_numbers?.data?.find((item: any) => item.company_number === event.target.value);
                                        handleCompanyNumberChange({ target: { value: selectedCompany } });
                                    }}
                                    sx={{
                                        color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark,
                                        '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark,
                                        },
                                        '& .MuiSvgIcon-root': {
                                        color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark,
                                        },
                                    }}
                                    MenuProps={{
                                        PaperProps: {
                                        sx: {
                                            backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.light,
                                            color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark,
                                        },
                                        },
                                    }}
                                    className={styles.selectText}
                                    >
                                    {company_numbers?.data?.map((item: any) => (
                                        <MenuItem
                                        className={styles.selectMenuItem}
                                        key={item?.company_number}
                                        value={item.company_number}
                                        sx={{ 
                                            fontWeight: 600, 
                                            color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark,
                                            '&:hover': {
                                            backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark,
                                            color: theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.light,
                                            },
                                        }}
                                        >
                                        <Typography
                                            sx={{ color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark, fontWeight: 600 }}
                                        >
                                            {item?.company_number}
                                        </Typography>
                                        </MenuItem>
                                    ))}
                                    </Select>
                                </FormControl>

                                {!isWarehouseRoute && (
                                <FormControl className={styles.formControl}>
                                    <InputLabel className={styles.selectLabel}>Division</InputLabel>
                                    <Select
                                    value={
                                        division_numbers?.data?.some((item: any) => item.division_number === divisionSelection?.division_number)
                                        ? divisionSelection?.division_number
                                        : ''
                                    }
                                    label="Division"
                                    size="small"
                                    onChange={(event) => {
                                        const selectedDivision = division_numbers?.data?.find((item: any) => item.division_number === event.target.value);
                                        handleDivisionNumberChange({ target: { value: selectedDivision } });
                                    }}
                                    sx={{
                                        color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark,
                                        '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark,
                                        },
                                        '& .MuiSvgIcon-root': {
                                        color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark,
                                        },
                                    }}
                                    MenuProps={{
                                        PaperProps: {
                                        sx: {
                                            backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.light,
                                            color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark,
                                        },
                                        },
                                    }}
                                    className={styles.selectText}
                                    >
                                    {division_numbers?.data?.map((item: any) => (
                                        <MenuItem
                                            className={styles.selectMenuItem}
                                            key={item?.division_number}
                                            value={item.division_number}
                                            sx={{ 
                                                fontWeight: 600, 
                                                color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark,
                                                '&:hover': {
                                                backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark,
                                                color: theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.light,
                                                },
                                            }}
                                        >
                                        <Typography
                                            sx={{ 
                                            color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark, 
                                            fontWeight: 600 
                                            }}
                                        >
                                            {item?.division_number}
                                        </Typography>
                                        </MenuItem>
                                    ))}
                                    </Select>
                                </FormControl>
                                )}

                                {isWarehouseRoute && (
                                <FormControl className={styles.selectControl}>
                                    <InputLabel className={styles.selectLabel}>WH#</InputLabel>
                                    <Select
                                        disabled={!companySelection?._id}
                                        label="Warehouse"
                                        variant="outlined"
                                        size="small"
                                        value={warehouseSelection?._id || ''}
                                        onChange={(event) => {
                                            const selectedWarehouse = warehouses?.data?.find((item: any) => item._id === event.target.value);
                                            if (selectedWarehouse) {
                                                setWarehouseSelection(selectedWarehouse);
                                                dispatch(setSelectedWarehouse(selectedWarehouse));
                                            }
                                        }}
                                        sx={{
                                            color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark,
                                            '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark,
                                            },
                                            '& .MuiSvgIcon-root': {
                                            color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark,
                                            },
                                        }}
                                        MenuProps={{
                                            PaperProps: {
                                            sx: {
                                                backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.light,
                                                color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark,
                                            },
                                            },
                                        }}
                                        className={styles.selectText}
                                    >
                                        {warehouses?.data?.map((item: any) => (
                                            <MenuItem
                                            className={styles.selectMenuItem}
                                            key={item?._id}
                                            value={item._id}
                                            sx={{ fontWeight: 600, color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark }}
                                        >
                                            <Typography
                                            sx={{ color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark, fontWeight: 600 }}
                                            >
                                            {item?.description || item?.warehouse_number || ''}
                                            </Typography>
                                        </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                )}
                            </Box>
                            </MenuItem>
                        )}
                        {pages.map((page) => (
                        <MenuItem
                            key={page.id}
                            onClick={() => {
                            handleCloseNavMenu();
                            navigate(page.link);
                            }}
                        >
                            <Typography
                            sx={{
                                color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark,
                                fontWeight: 600,
                            }}
                            className={styles.mobileMenuItem}
                            >
                            {page.title}
                            </Typography>
                        </MenuItem>
                        ))} 
                    </Menu>
                </Box>                
            </Box>

        );
    }, [
        isMobile, location.pathname, anchorElNav, isSidebarOpen, pages, isOrgUnitRoute, 
        company_numbers, division_numbers, companySelection, divisionSelection, 
        theme.palette.mode, theme.palette.primary, handleLogoClick, handleCloseNavMenu, handleCompanyNumberChange, handleDivisionNumberChange, warehouses, warehouseSelection, isWarehouseRoute
    ]);

    const renderDesktopMenu = useMemo(() => {
        if (isMobile) {
        return null;
    }

    return(
        <Box
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              cursor: 'pointer',
              width: 'auto',
              height: '100%',
              px: 1,
              '&:hover': { opacity: 0.8 },
            }}
          >
            <Box className={styles.logoContainer} onClick={handleLogoClick}>
              <LogoReversed
                className={styles.logo}
                style={{
                    fill: theme.palette.primary.light,
                    width: 'auto',
                    height: 58,
                    transition: 'opacity 0.2s ease',
                }}
                />
            </Box>
            {!isOrgUnitRoute && 
            <Box className={styles.companyDivisionContainer}> 
                <FormControl className={styles.selectControl}>
                  <InputLabel className={styles.selectLabel}>
                    <Typography sx={{ color: theme.palette.primary.light, fontWeight: 600 }}>C#</Typography>
                  </InputLabel>
                <Select
                  value={
                    company_numbers?.data?.some((item: any) => item.company_number === companySelection?.company_number)
                      ? companySelection?.company_number
                      : ''
                  }
                  label="Company"
                  size="small"
                  onChange={(event) => {
                    const selectedCompany = company_numbers?.data?.find((item: any) => item.company_number === event.target.value);
                    handleCompanyNumberChange({ target: { value: selectedCompany } });
                  }}
                  sx={{
                    color: theme.palette.primary.light,
                    '& .MuiOutlinedInput-notchedOutline': { borderColor: theme.palette.primary.light },
                    '& .MuiSvgIcon-root': { color: theme.palette.primary.light },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.light,
                        color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark,
                      },
                    },
                  }}
                  className={styles.selectText}
                  renderValue={(value) => {
                    return <Typography sx={{color: theme.palette.primary.light, fontWeight: 600}}>{value}</Typography>;
                  }}
                >
                  {company_numbers?.data?.map((item: any) => (
                    <MenuItem
                      className={styles.selectMenuItem}
                      key={item?.company_number}
                      value={item.company_number}
                      sx={{ fontWeight: 600, color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark }}
                    >
                      <Typography
                        sx={{ color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark, fontWeight: 600 }}
                      >
                        {item?.company_number}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
                </FormControl>

                {!isWarehouseRoute && (
                <FormControl className={styles.selectControl}>
                  <InputLabel className={styles.selectLabel}>
                  <Typography sx={{ color: theme.palette.primary.light, fontWeight: 600 }}>D#</Typography>
                </InputLabel>
                <Select
                  value={
                    division_numbers?.data?.some((item: any) => item.division_number === divisionSelection?.division_number)
                      ? divisionSelection?.division_number
                      : ''
                  }
                  label="Division"
                  size="small"
                  disabled={!companySelection?._id}
                  onChange={(event) => {
                    const selectedDivision = division_numbers?.data?.find((item: any) => item.division_number === event.target.value);
                    handleDivisionNumberChange({ target: { value: selectedDivision } });
                  }}
                  sx={{
                    color: theme.palette.primary.light,
                    '& .MuiOutlinedInput-notchedOutline': { borderColor: theme.palette.primary.light },
                    '& .MuiSvgIcon-root': { color: theme.palette.primary.light },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.light,
                        color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark,
                      },
                    },
                  }}
                  className={styles.selectText}
                  renderValue={(value) => {
                    return <Typography sx={{color: theme.palette.primary.light, fontWeight: 600}}>{value}</Typography>;
                  }}
                >
                  {division_numbers?.data?.map((item: any) => (
                    <MenuItem
                      className={styles.selectMenuItem}
                      key={item?.division_number}
                      value={item.division_number}
                      sx={{ fontWeight: 600, color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark }}
                    >
                      <Typography
                        sx={{ color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark, fontWeight: 600 }}
                      >
                        {item?.division_number}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
                </FormControl>
                )}
            
                {isWarehouseRoute && (
                <FormControl className={styles.selectControl}>
                  <InputLabel className={styles.selectLabel}>
                    <Typography sx={{ color: theme.palette.primary.light, fontWeight: 600 }}>WH#</Typography>
                  </InputLabel>
                    <Select
                    disabled={!companySelection?._id}
                    label="Warehouse"
                    variant="outlined"
                    size="small"
                    value={warehouseSelection?._id || ''}
                    onChange={(event) => {
                        const selectedWarehouse = warehouses?.data?.find((item: any) => item._id === event.target.value);
                        if (selectedWarehouse) {
                            setWarehouseSelection(selectedWarehouse);
                            dispatch(setSelectedWarehouse(selectedWarehouse));
                        }
                    }}
                    sx={{
                        color: theme.palette.primary.light,
                        '& .MuiOutlinedInput-notchedOutline': { borderColor: theme.palette.primary.light },
                        '& .MuiSvgIcon-root': { color: theme.palette.primary.light },
                    }}
                    MenuProps={{
                        PaperProps: {
                        sx: {
                            backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.light,
                            color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark,
                        },
                        },
                    }}
                    className={styles.selectText}
                    renderValue={() => {
                        return <Typography sx={{color: theme.palette.primary.light, fontWeight: 600}}>
                        {warehouseSelection?.warehouse_number || ''}
                        </Typography>;
                    }}
                    >
                        {warehouses?.data?.map((item: any) => (
                            <MenuItem
                            className={styles.selectMenuItem}
                            key={item?._id}
                            value={item._id}
                            sx={{ fontWeight: 600, color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark }}
                        >
                            <Typography
                            sx={{ color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark, fontWeight: 600 }}
                            >
                            {item?.description || item?.warehouse_number || ''}
                            </Typography>
                        </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                )}
            </Box>}
          </Box>
        );
    }, [isMobile, location.pathname, anchorElNav, isSidebarOpen, pages, isOrgUnitRoute, 
        company_numbers, division_numbers, companySelection, divisionSelection, 
        theme.palette.mode, theme.palette.primary, warehouses, warehouseSelection, isWarehouseRoute]);

  return {
    renderMobileMenu,
    renderDesktopMenu,
    isSidebarOpen,
    toggleSidebar,
    anchorElNav,
    onClose,
    companySelection,
    setCompanySelection,
    divisionSelection,
    setDivisionSelection,
    warehouseSelection,
    setWarehouseSelection,
    handleCloseNavMenu
  };
};

export default useRenderMenus;