import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ErrorState {
    type: string;
    status: number;
    message: string;
    fields: any;
    errors: any
    loading: boolean;
}

const initialState: ErrorState = {
  type: '',
  status: 0,
  message: '',
  fields: {},
  errors: {},
  loading: false
};

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<{ type: string; status: number; message: string; fields: any; errors: any }>) => {
      state.type = action.payload.type;
      state.status = action.payload.status;
      state.message = action.payload.message;
      state.fields = action.payload.fields;
      state.errors = action.payload.errors;
    },
    resetError: (state) => {
      state.type = '';
      state.status = 0;
      state.message = '';
      state.fields = {};
      state.errors = {};
      state.loading = false;
    },
  }
});

export const { setError, resetError } = errorSlice.actions;
export default errorSlice.reducer;

// Selectors
export const selectError = (state: { error: ErrorState }) => state.error.type;
export const selectErrorMessage = (state: { error: ErrorState }) => state.error.message;
export const selectIsValidating = (state: { error: ErrorState }) => state.error.loading;