import {ReactComponent as ProjectIcon} from '../../assets/projects.svg';
import {ReactComponent as StartIcon} from '../../assets/starts-suite.svg';
import {ReactComponent as StagingOrdersIcon} from '../../assets/assurance.svg';
import {ReactComponent as WarehouseIcon} from '../../assets/warehouse.svg';
import {ReactComponent as OrgUnitsIcon} from '../../assets/organizational-units.svg';
import ConstructionSharpIcon from '@mui/icons-material/ConstructionSharp';
import PlayArrowSharpIcon from '@mui/icons-material/PlayArrowSharp';
import AssuredWorkloadSharpIcon from '@mui/icons-material/AssuredWorkloadSharp';
import ObjectID from "bson-objectid";
import WarehouseSharpIcon from '@mui/icons-material/WarehouseSharp';
import LanSharpIcon from '@mui/icons-material/LanSharp';
import HandshakeSharpIcon from '@mui/icons-material/HandshakeSharp';
import ManageAccountsSharpIcon from '@mui/icons-material/ManageAccountsSharp';
import { URLS } from '../../routes/urls/urls';

export const pageLinks = {
    admin: [
        {id: String(new ObjectID()), title: 'Organizational Units', desc: "Manage Organizational Units", imgSrc: OrgUnitsIcon, link: URLS.companies, icon: <LanSharpIcon fontSize="large" />},
        {id: String(new ObjectID()), title: 'Users', desc: "Manage Users", imgSrc: ManageAccountsSharpIcon, link: URLS.adminManageUsers, icon: <ManageAccountsSharpIcon fontSize="large" />},
    ],
    assurance: [
        {id: String(new ObjectID()), title: 'Assurance', desc: "Assure Starts", imgSrc: StagingOrdersIcon,link: URLS.assurance, icon: <AssuredWorkloadSharpIcon fontSize="large" />},
        {id: String(new ObjectID()), title: 'Contracting', desc: "Manage Proposals & Contracts", imgSrc: HandshakeSharpIcon,link: URLS.contracting, icon: <HandshakeSharpIcon fontSize="large" />},
    ],
    app: [
        {id: String(new ObjectID()), title: 'Projects', desc: "Manage Projects", imgSrc: ProjectIcon, link: URLS.projects, icon: <ConstructionSharpIcon fontSize="large" />},
        {id: String(new ObjectID()), title: 'Starts', desc: "Manage Subjobs", imgSrc: StartIcon,link: URLS.starts, icon: <PlayArrowSharpIcon fontSize="large" />},
    ],
    warehouse: [
        {id: String(new ObjectID()), title: 'Warehouse', desc: "View/Manage Warehouse", imgSrc: WarehouseIcon, link: URLS.warehouseOrders, icon: <WarehouseSharpIcon fontSize="large" />},
    ]
} 