import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getUserAvatar, reset } from '../userSlice';

/**
 * Custom hook to fetch and manage user avatar
 * @param username - The username to fetch avatar for
 * @returns Object containing avatar URL and loading state
 */
const useUserAvatar = (username?: string) => {
  const dispatch = useAppDispatch();
  const { avatarUrl, isLoading, isError, message } = useAppSelector(state => state.user);
  
  // Fetch avatar when username is available
  useEffect(() => {
    if (username) {
      dispatch(getUserAvatar({ username }));
    }
    
    // Cleanup function to reset errors and revoke blob URL
    return () => {
      if (isError) {
        dispatch(reset());
      }
      
      if (avatarUrl && avatarUrl.startsWith('blob:')) {
        URL.revokeObjectURL(avatarUrl);
      }
    };
  }, [username, dispatch]);
  
  return {
    avatarUrl,
    isLoading,
    isError,
    errorMessage: message
  };
};

export default useUserAvatar; 